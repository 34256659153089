
import { Component, Prop, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import { Tenant } from '@/models/tenant';
import TimezonesSelect from '@/components/inputs/TimezonesSelect.vue';
import CurrencyCodesSelect from '@/components/inputs/CurrencyCodesSelect.vue';
import MeasurementsSelect from '@/components/inputs/MeasurementsSelect.vue';
import SubsidiariesSelect from '@/components/inputs/SubsidiariesSelect.vue';
import Button from '@/components/Button.vue';
import TenantServicesSelect from '@/components/inputs/TenantServicesSelect.vue';
import { UserRole } from '@/models/user';
import { PersistentGetters } from '@/store/persistent/enums';

@Component({
  components: {
    'sc-timezones-select': TimezonesSelect,
    'sc-currencycodes-select': CurrencyCodesSelect,
    'sc-measurements-select': MeasurementsSelect,
    'sc-subsidiaries-select': SubsidiariesSelect,
    'sc-tenantservices-select': TenantServicesSelect,
    'sc-button': Button,
  },
})
export default class EndCustomerDetailProperties extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  public tenant!: Tenant;

  public editTenant: Tenant = Tenant.GetEmpty();

  public created() {
    this.editTenant = Tenant.GetCopy(this.tenant);
  }

  public handleChange(): void {
    this.editTenant = Tenant.GetCopy(this.editTenant);
  }

  public get isEnabled() {
    if (this.editTenant && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    tenantsService.update(this.editTenant).finally(() => this.$spinner.removeSpinner());
  }

  public get isReadOnly(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role !== UserRole.Superadmin;
  }
}
