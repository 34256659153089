import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import state from '@/store/state';
import RootState from '@/store/rootState';
import persistent from '@/store/persistent/persistent';
import quote from '@/store/quote/quote';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<RootState>({​​
  storage: window.localStorage,
  modules: ['persistent'],
}​​);

const store: StoreOptions<RootState> = {
    state,
    actions,
    mutations,
    getters,
    modules: {
      persistent,
      quote,
    },
    plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
