import axios from 'axios';
import i18n from '@/i18n';

export function LocalizationInterceptor() {
    axios.interceptors.request.use(async (config) => {
        if (config.headers) {
            config.headers['Accept-Language'] = i18n.locale;
            return config;
        }
    }, (error) => {
        return Promise.reject(error);
    });
}
