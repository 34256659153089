
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { AccessLevel, User, UserRole } from '@/models/user';
import { Tenant } from '@/models/tenant';
import { Technology } from '@/models/technology';
import { tenantsService } from '@/services/tenants.service';
import { Routes } from '@/router';
import Button from '@/components/Button.vue';
import { PersistentGetters } from '@/store/persistent/enums';
import MaintainTechnology from '@/components/technologies/MaintainTechnology.vue';
import { Mode } from '@/store/enums';
import { technologiesService } from '../../services/technologies.service';

@Component({
  components: {
    'sc-maintain-tech': MaintainTechnology,
    'sc-button': Button,
  },
})
export default class TechnologiesWithParent extends Vue {

  @Prop({ required: false, default: Tenant.GetEmpty() })
  public tenant!: Tenant;

  @Ref('maintainTech')
  public maintainTech!: MaintainTechnology;

  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;
  public routes = Routes;

  public technologies: Technology[] = [];

  public async created() {
    await this.getTechnologies();
  }

  public async startCreate() {
     this.maintainTech.openModalExternal(
      Mode.Create,
      Technology.GetEmpty(),
     );
  }

  public async startEdit(technology: Technology) {
     this.$spinner.showSpinner();
     const editableTech: Technology = await technologiesService.getById(technology.id);
     this.$spinner.removeSpinner();

     this.maintainTech.openModalExternal(
      this.isReadOnly ? Mode.View : Mode.Edit,
      editableTech,
     );
  }

  public confirmDelete(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('technology.dialog.delete').toString() + ` <b>${name}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteTechnology(id),
    });
  }

  public async modalClose() {
    await this.getTechnologies();
  }

  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isReadOnly(): boolean {
    return this.currentUser.role === UserRole.Viewer;
  }

  public filterTechnology(row: any, input: string) {
    if (input === null || input === undefined) {
      return false;
    }
    const type = row.technologyType;
    if (type === null || type === undefined) {
      return false;
    }
    const localisedType =
      this.$i18n.t(`technology.technology_type.${row.technologyType}`).toString().toLocaleLowerCase();
    const localisedInput = input.toLocaleLowerCase();
    return localisedType.includes(localisedInput);
  }

  private async getTechnologies() {
    this.$spinner.showSpinner();
    if (this.tenant && this.tenant.id > 0) {
      this.technologies = await tenantsService.getTechnologies(this.tenant.id);
    }
    this.$spinner.removeSpinner();
  }

  private deleteTechnology(id: number): void {
     this.$spinner.showSpinner();
     technologiesService
      .delete(id)
      .then(() => {
        this.technologies = this.technologies.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }
}
