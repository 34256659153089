import { AccessLevel, User, UserRole } from '@/models/user';

export function hasPermission(user: User, accessLevel: AccessLevel): boolean {
  if (user.role === UserRole.Superadmin) {
    return true;
  }

  if (accessLevel === AccessLevel.SubsidiaryAdmin && user.role === UserRole.Admin && hasSubsidiaryScope(user)) {
    return true;
  } else if (accessLevel === AccessLevel.SubsidiaryViewer && hasSubsidiaryScope(user)) {
    return true;
  } else if (accessLevel === AccessLevel.TenantAdmin) {
    if (user.role === UserRole.Admin && hasSubsidiaryScope(user)) {
      return true;
    } else if (user.role === UserRole.Admin && hasTenantScope(user)) {
      return true;
    }
  } else if (accessLevel === AccessLevel.TenantViewer) {
    if (hasSubsidiaryScope(user)) {
      return true;
    } else if (hasTenantScope(user)) {
      return true;
    }
  }

  return false;
}

export function hasSubsidiaryScope(user: User): boolean {
  return user.subsidiaryId != null && user.subsidiaryId > 0;
}

export function hasTenantScope(user: User): boolean {
  return user.tenantId != null && user.tenantId > 0;
}

export function hasEndCustomerScope(user: User): boolean {
  return user.endCustomerId != null && user.endCustomerId > 0;
}
