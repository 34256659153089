
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Menu from '@/components/Menu.vue';
import Spinner from '@/components/Spinner.vue';
import { PersistentActions, PersistentGetters } from './store/persistent/enums';
import { RootActions } from './store/enums';
import { mastersService } from './services/masters.service';
import { usersService } from './services/usersService';
import { LoginActions } from './models/enums/AuthenticationActions';
import { User } from './models/user';
import { endCustomersService } from './services/endCustomers.service';
import TermsAgreementModal from './components/TermsAgreementModal.vue';

@Component({
  components: {
    'sc-header': Header,
    'sc-menu': Menu,
    'sc-spinner': Spinner,
    'sc-termsAgreementModal': TermsAgreementModal,
  },
})
export default class App extends Vue {
  public showMenu: boolean = false;

  public created() {
    if (this.$route.name !== LoginActions.LoginCallback) {
      this.$auth.isLoggedIn().then((loggedIn) => {
        if (loggedIn === false) {
          this.$auth.login();
        } else {
          this.getUserInfo();
        }
      });
    }
  }

  public get loadedInfoUser(): boolean {
      return this.$store.getters.userInfoLoaded;
  }

  public udpateShowMenu() {
    this.showMenu = !this.showMenu;
  }

  private async getTimezones() {
    this.$spinner.showSpinner();
    const timezones = await mastersService.getTimezones().finally(() => this.$spinner.removeSpinner());
    this.$store.dispatch(PersistentActions.SetTimezones, timezones);
  }

  private async getCurrencyCodes() {
    this.$spinner.showSpinner();
    const currencyCodes = await mastersService.getCurrencyCodes().finally(() => this.$spinner.removeSpinner());
    this.$store.dispatch(PersistentActions.SetCurrencyCodes, currencyCodes);
  }

  private async getMeasurements() {
    this.$spinner.showSpinner();
    const measurement = await mastersService.getMeasurementUnits().finally(() => this.$spinner.removeSpinner());
    this.$store.dispatch(PersistentActions.SetMeasurements, measurement);
  }

  private async getServices() {
    this.$spinner.showSpinner();
    const services = await mastersService.getServices().finally(() => this.$spinner.removeSpinner());
    this.$store.dispatch(PersistentActions.SetServices, services);
  }

  private async getMaterialGroups(endCustomerId: number | null) {
    if (endCustomerId == null) {
      this.$store.dispatch(PersistentActions.SetMaterialGroups, []);
    } else {
      this.$spinner.showSpinner();
      endCustomersService
        .getMaterialGroups(endCustomerId)
        .then((response) => {
          this.$store.dispatch(PersistentActions.SetMaterialGroups, response);
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private async getTechnologies(endCustomerId: number | null) {
    if (endCustomerId == null) {
      this.$store.dispatch(PersistentActions.SetTechnologies, []);
    } else {
      this.$spinner.showSpinner();
      endCustomersService
        .getTechnologies(endCustomerId)
        .then((response) => {
          this.$store.dispatch(PersistentActions.SetTechnologies, response);
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private async getAddresses(endCustomerId: number | null) {
    if (endCustomerId == null) {
      this.$store.dispatch(PersistentActions.SetAddresses, []);
    } else {
      this.$spinner.showSpinner();
      endCustomersService
        .getAddresses(endCustomerId)
        .then((response) => {
          this.$store.dispatch(PersistentActions.SetAddresses, response);
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private async getRejectReasons(endCustomerId: number | null) {
    if (endCustomerId == null) {
      this.$store.dispatch(PersistentActions.SetRejectReasons, []);
    } else {
      this.$spinner.showSpinner();
      endCustomersService
        .getRejectReasons(endCustomerId)
        .then((response) => {
          this.$store.dispatch(PersistentActions.SetRejectReasons, response);
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private getUserInfo() {
    this.$spinner.showSpinner();
    usersService
      .me()
      .then((user) => {
        this.$store.dispatch(PersistentActions.SetCurrentUser, user);
        this.getTimezones();
        this.getCurrencyCodes();
        this.getMeasurements();
        this.getServices();
        this.getMaterialGroups(this.User.endCustomerId);
        this.getTechnologies(this.User.endCustomerId);
        this.getRejectReasons(this.User.endCustomerId);
        this.getAddresses(this.User.endCustomerId);
        if (this.User.isAcceptedTermsOfAgreement) {
          this.$store.dispatch(RootActions.SetTermsAcceptedSession, true);
        }
      })
      .catch((error) => {
        throw new Error(error);
      })
      .finally(() => {
        this.$store.dispatch(RootActions.SetUserInfoLoaded, true);
        this.$spinner.removeSpinner();
      });
  }

  private get User(): User {
    return this.$store.getters[PersistentGetters.CurrentUser] as User;
  }
}
