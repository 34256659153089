
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Mode } from '@/store/enums';
    import { PersistentGetters } from '@/store/persistent/enums';
    import Button from '@/components/Button.vue';
    import { AccessLevel } from '@/models/user';
    import { hasPermission } from '@/utils/permissionUtils';

    @Component({
        components: {
            'sc-button': Button,
        },
    })
    export default class ModalForm extends Vue {

        @Prop({ required: false, default: false })
        public modalVisible!: boolean;

        @Prop({ required: false, default: Mode.View })
        public mode!: Mode;

        @Prop({ required: false, default: false })
        public canSave!: boolean;

        public form: any = null;

        public get isEditMode() {
            return this.mode === Mode.Edit;
        }

        public get isCreateMode() {
            return this.mode === Mode.Create;
        }

        public get isViewMode() {
            return this.mode === Mode.View;
        }

        public get actionTitle() {
            if (this.isCreateMode) {
                return this.$i18n.t('action.create').toString();
            } else if (this.isEditMode) {
                if (this.isReadOnly) {
                    return this.$i18n.t('action.view').toString();
                } else {
                    return this.$i18n.t('action.save').toString();
                }
            } else {
                return this.$i18n.t('action.close').toString();
            }
        }

        public async onSubmit() {
            this.$emit('onSubmit');
        }

        public async onCancel() {
            this.$emit('onCancel');
        }

        public async onInput() {
            this.$emit('onInput');
        }

        public updateMode(mode: Mode) {
            this.mode = mode;
        }

        public toggleModal(visible: boolean) {
            this.modalVisible = visible;
        }

        public get isReadOnly(): boolean {
            return !(this.isSuperAdmin || this.isTenantAdmin);
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isSuperAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
        }

        public get isTenantAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
        }
    }
