import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { reportsService } from './reports.service';
import { SaleOrder } from '../models/saleOrder';
import { SaleOrderFilter } from '@/models/saleOrderFilter';

class SaleOrdersService {

    public get(filter: SaleOrderFilter): Promise<SaleOrder[]> {
        return axios
            .get<SaleOrder[]>(`${BaseUrl}/sale-orders`, { params: filter })
            .then((response: any) => {
                responseHandler.handleDates(response.data);
                return response.data;
            });
    }

    public getById(id: number): Promise<SaleOrder> {
        return axios.get<SaleOrder>(`${BaseUrl}/sale-orders/${id}`).then((response: any) => {
          responseHandler.handleDates(response.data);
          return response.data;
        });
    }

    public async showReport(id: number) {
        const url = `${BaseUrl}/sale-orders/${id}/report`;
        return await reportsService.showReport(url);
    }
}

export const saleOrdersService = new SaleOrdersService();
