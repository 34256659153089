
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import Button from '../Button.vue';
import MaintainEndCustomer from './MaintainEndCustomer.vue';
import { Mode } from '@/store/enums';
import { EndCustomer } from '@/models/endCustomer';

@Component({
  components: {
    'sc-maintain-end-customer': MaintainEndCustomer,
    'sc-button': Button,
  },
})
export default class MaintainEndCustomerModal extends Vue {
  public form: any = null;
  public modalVisible = false;
  public currentMode: Mode = Mode.View;
  public endCustomer: EndCustomer = EndCustomer.GetEmpty();

  @Ref('a')
  public maintainEndCustomer!: MaintainEndCustomer;

  public handleChange(): void {
    this.endCustomer = EndCustomer.GetCopy(this.endCustomer);
  }

  public openModalCreate(): void {
    this.currentMode = Mode.Create;
    this.endCustomer = EndCustomer.GetEmpty();
    this.modalVisible = true;
  }

  public openModalEdit(endCustomer: EndCustomer): void {
    this.currentMode = Mode.Edit;
    this.endCustomer = EndCustomer.GetCopy(endCustomer);
    this.modalVisible = true;
  }

  public closeModal() {
    this.modalVisible = false;
    this.$emit('onClose');
  }
}
