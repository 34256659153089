
import { Tenant } from '@/models/tenant';
import { Routes } from '@/router';
import { tenantsService } from '@/services/tenants.service';
import { Component, Vue } from 'vue-property-decorator';
import TenantProperties from './TenantProperties.vue';
import { AccessLevel, UserRole } from '@/models/user';
import { PersistentGetters } from '@/store/persistent/enums';
import MaintainTenantDetails from '@/components/tenants/MaintainTenantDetails.vue';
import MaintainTenantServices from '@/components/tenants/MaintainTenantServices.vue';
import EndCustomersWithParent from '@/views/endCustomers/EndCustomersWithParent.vue';
import MarkupsWithParent from '@/views/markups/MarkupsWithParent.vue';
import TechnologiesWithParent from '@/views/technologies/TechnologiesWithParent.vue';
import UsersWithParent from '@/views/users/UsersWithParent.vue';
import MaterialGroupsWithParent from '@/views/materials/MaterialGroupsWithParent.vue';
import ShippingsWithParent from '@/views/shippings/ShippingsWithParent.vue';
import { hasPermission } from '@/utils/permissionUtils';

@Component({
  components: {
    'sc-tenant-properties': TenantProperties,
    'sc-tenant-users': UsersWithParent,
    'sc-maintain-tenant': MaintainTenantDetails,
    'sc-tenant-end-customers': EndCustomersWithParent,
    'sc-maintain-tenant-services': MaintainTenantServices,
    'sc-tenant-markups': MarkupsWithParent,
    'sc-tenant-technologies': TechnologiesWithParent,
    'sc-tenant-material-groups': MaterialGroupsWithParent,
    'sc-tenant-shippings': ShippingsWithParent,
  },
})
export default class TenantDetail extends Vue {
  public id: number = 0;
  public tenant: Tenant = Tenant.GetEmpty();
  public selectedTab: number = 0;

  public async created(): Promise<void> {
    if (!this.$route.params || !this.$route.params.id) {
      this.$router.push({ name: Routes.Tenants });
    } else {
      this.id = +this.$route.params.id;
      await this.setTenantById(this.id);
    }
  }


  public get isTenantAdminOrAbove(): boolean {
    return this.isSuperAdmin ||
            this.isSubsidiaryAdmin || this.isSubsidiaryViewer ||
            this.isTenantAdmin;
  }

  public get isShiftAllowed(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role === UserRole.Admin;
  }

  public get isBendPartCategoryAllowed(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role === UserRole.Admin;
  }

  public async setTenantById(id: number) {
    this.$spinner.showSpinner();
    this.tenant = await tenantsService.getById(id);
    this.$spinner.removeSpinner();
  }

  public get hasTenant(): boolean {
    return this.tenant.id > 0;
  }

  private get isSuperAdmin(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SuperAdmin);
  }

  private get isSubsidiaryAdmin(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SubsidiaryAdmin);
  }

  private get isSubsidiaryViewer(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SubsidiaryViewer);
  }

  private get isTenantAdmin(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.TenantAdmin);
  }

  public async update() {
    await this.setTenantById(this.tenant.id);
  }
}
