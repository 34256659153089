
import { Component, Vue, Prop } from 'vue-property-decorator';
import { endCustomersService } from '@/services/endCustomers.service';
import { EndCustomer } from '@/models/endCustomer';
@Component
export default class EndCustomerSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: EndCustomer[] = [];

  public mounted(): void {
    this.getItems();
  }
  public async getItems() {
    this.$spinner.showSpinner();
    this.items = await endCustomersService.get();
    this.$spinner.removeSpinner();
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
