
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { endCustomersService } from '@/services/endCustomers.service';
    import { EndCustomer } from '@/models/endCustomer';
    import Button from '../Button.vue';
    import { Mode } from '@/store/enums';
    import { hasPermission, hasTenantScope } from '@/utils/permissionUtils';
    import { PersistentGetters } from '@/store/persistent/enums';
    import { AccessLevel, UserRole } from '@/models/user';
    import { PriceCategoryMarkupEnum } from '@/models/enums/MaintenanceMode';
    import TenantsSelect from '../inputs/TenantsSelect.vue';
    import MarkupsSelect from '../inputs/MarkupsSelect.vue';

    @Component({
        components: {
            'sc-tenants-select': TenantsSelect,
            'sc-markups-select': MarkupsSelect,
            'sc-button': Button,
        },
    })
    export default class MaintainEndCustomer extends Vue {
        @Prop({ default: false })
        public isModal!: boolean;
        @Prop({ default: Mode.Create })
        public mode!: Mode;
        @Prop({ default: EndCustomer.GetEmpty() })
        public endCustomer!: EndCustomer;

        public maintainedEndCustomer: EndCustomer = EndCustomer.GetEmpty();
        public form: any = null;
        public priceCategories = Object.keys(PriceCategoryMarkupEnum)
            .filter((item) => {
                const value = Number(item);
                return !isNaN(value) && value > 0;
            })
            .map((item) => Number(item));

        public created() {
            this.maintainedEndCustomer = this.endCustomer;
        }

        public handleChange(): void {
            this.maintainedEndCustomer = EndCustomer.GetCopy(this.maintainedEndCustomer);
        }

        public async onSubmit() {
            this.$spinner.showSpinner();
            if (this.mode === Mode.Create) {
                endCustomersService
                    .create(this.maintainedEndCustomer)
                    .then(() => {
                        this.closeModal();
                    })
                    .finally(() => this.$spinner.removeSpinner());
            } else if (this.mode === Mode.Edit) {
                endCustomersService.update(this.maintainedEndCustomer).finally(() => {
                    this.$emit('updated');
                    this.$spinner.removeSpinner();
                });
                this.closeModal();
            }
        }

        public closeModal() {
            this.$emit('onClose');
        }

        public get actionTitle() {
            if (this.mode === Mode.Create) {
                return this.$i18n.t('action.create').toString();
            } else if (this.mode === Mode.Edit) {
                if (this.isReadOnly) {
                    return this.$i18n.t('action.view').toString();
                } else {
                    return this.$i18n.t('action.edit').toString();
                }
            } else {
                return this.$i18n.t('action.close').toString();
            }
        }
        public get isReadOnly(): boolean {
            return !(this.isSuperAdmin || this.isSubsidiaryAdmin || this.isTenantAdmin);
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isSuperAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
        }

        public get isSubsidiaryAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.SubsidiaryAdmin);
        }

        public get isTenantAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
        }

        public get isExactlyTenantAdmin(): boolean {
            return this.currentUser.role === UserRole.Admin && hasTenantScope(this.currentUser);
        }

        public get isEnabled() {
            if (this.maintainedEndCustomer && this.$refs.form) {
                const refForm = this.$refs.form as any;
                return refForm.checkValidity();
            }
            return false;
        }

        public get hasTenant(): boolean {
            return this.maintainedEndCustomer?.tenantId > 0;
        }
    }
