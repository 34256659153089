
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LocalSpinner extends Vue {
  @Prop({ required: true, default: false })
  public showSpinner!: boolean;

  @Prop({ required: false, default: true })
  public fullPage!: boolean;
}
