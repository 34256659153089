
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Guidelines from '@/components/quotes/Guidelines.vue';

@Component({
  components: {
    'sc-guidelines': Guidelines,
  },
})
export default class AddPartFile extends Vue {
  public dropFiles: File[] | null = null;
  public fileInputKey: number = 0;

  @Prop({ required: false, default: true })
  public showBigSelector!: boolean;

  @Prop({ required: false, default: false })
  public paddingUploader!: boolean;

  @Watch('dropFiles')
  private fileAdded() {
    if (!this.dropFiles) {
      return;
    }

    this.$emit('partAdded', this.dropFiles);

    // if (this.dropFiles && this.dropFiles.length > 0) {
    //   this.dropFiles.forEach((file) => {
    //     this.$emit('partAdded', file);
    //   });
    // }

    this.fileInputKey++;
    this.dropFiles = null;
  }
}
