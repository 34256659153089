
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Routes } from '@/router';
import Button from '@/components/Button.vue';
import { AccessLevel, User, UserRole } from '@/models/user';
import { hasPermission } from '@/utils/permissionUtils';
import { PersistentGetters } from '@/store/persistent/enums';
import { endCustomersService } from '@/services/endCustomers.service';
import { EndCustomer } from '@/models/endCustomer';
import MaintainEndCustomerModal from '@/components/endCustomers/MaintainEndCustomerModal.vue';

@Component({
  components: {
    'sc-create-endcustomer': MaintainEndCustomerModal,
    'sc-button': Button,
  },
})
export default class EndCustomers extends Vue {
  @Ref('maintainEndCustomerModal')
  public maintainEndCustomerModal!: MaintainEndCustomerModal;

  public items: EndCustomer[] = [];
  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;
  public routes = Routes;

  public async created() {
    await this.getItems();
  }

  public confirmDelete(id: number): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('endCustomer.dialog.delete').toString() + ` <b>${id}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.delete(id),
    });
  }

  public async create() {
    this.maintainEndCustomerModal.openModalCreate();
  }

  public async edit(item: EndCustomer) {
    this.$router.push({ name: Routes.EndCustomerDetail, params: { id: item.id.toString() } });
  }

  public async modalClose() {
    await this.getItems();
  }

  private async getItems() {
    this.$spinner.showSpinner();
    endCustomersService.get()
    .then((response) => this.items = response)
    .finally(() => this.$spinner.removeSpinner());
  }

  private delete(id: number): void {
    this.$spinner.showSpinner();
    endCustomersService
      .delete(id)
      .then(() => {
        this.getItems();
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  public get currentUser(): User {
    return this.$store.getters[PersistentGetters.CurrentUser] as User;
  }

  public get canEdit(): boolean {
    return hasPermission(this.currentUser, AccessLevel.SubsidiaryAdmin);
  }

  public get isSuperAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
  }

    public get isReadOnly(): boolean {
    return this.currentUser.role === UserRole.Viewer;
  }
}
