
    import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
    import { EndCustomer } from '@/models/endCustomer';
    import { Address } from '@/models/address';
    import { Mode } from '@/store/enums';
    import Button from '@/components/Button.vue';
    import ListView from '@/components/ListView.vue';
    import MaintainAddress from '@/components/addresses/MaintainAddress.vue';
    import { endCustomersService } from '../../services/endCustomers.service';
    import { addressesService } from '../../services/addresses.service';

    @Component({
        components: {
            'sc-button': Button,
            'sc-list-view': ListView,
            'sc-maintain-address': MaintainAddress,
        },
    })
    export default class AddressesWithParent extends ListView {

        @Prop({ required: true })
        public endCustomer!: EndCustomer;

        @Ref('maintainAddress')
        public maintainAddress!: MaintainAddress;

        public addresses: Address[] = [];

        public async created() {
            await this.getAddresses();
        }

        public async modalClose() {
            await this.getAddresses();
        }

        public async createAddress() {
            this.maintainAddress.openModalExternal(
                Mode.Create,
                Address.GetEmpty(),
            );
        }

        public async editAddress(address: any) {
            this.$spinner.showSpinner();
            const editableAddress: Address = await addressesService.getById(address.id);
            this.$spinner.removeSpinner();

            this.maintainAddress.openModalExternal(
                this.isReadOnly ? Mode.View : Mode.Edit,
                editableAddress,
            );
        }

        public get hasEndCustomer(): boolean {
            return this.endCustomer.id > 0;
        }

        public get deleteAddressMessage(): string {
            const message = this.$i18n.t('address.dialog.delete').toString();
            return message;
        }

        protected deleteAddress(id: number): void {
            this.$spinner.showSpinner();
            addressesService
                .delete(id)
                .then(() => {
                    this.addresses = this.addresses.filter((x) => x.id !== id);
                })
                .finally(() => {
                    this.$spinner.removeSpinner();
                });
        }

        private async getAddresses() {
            this.$spinner.showSpinner();
            if (this.hasEndCustomer) {
                this.addresses = await endCustomersService.getAddresses(this.endCustomer.id);
            }
            this.$spinner.removeSpinner();
        }
    }
