
    import { Component, Ref, Vue } from 'vue-property-decorator';
    import { Routes } from '@/router';
    import Button from '@/components/Button.vue';
    import { User } from '@/models/user';
    import { hasEndCustomerScope } from '@/utils/permissionUtils';
    import { PersistentGetters } from '@/store/persistent/enums';
    import { saleOrdersService } from '@/services/saleOrders.service';
    import { SaleOrder } from '@/models/saleOrder';
    import SaleOrderInvoices from '../../components/saleOrders/SaleOrderInvoices.vue';
    import { SaleOrderFilter } from '@/models/saleOrderFilter';

    @Component({
        components: {
            'sc-invoices-modal': SaleOrderInvoices,
            'sc-button': Button,
        },
    })
    export default class SaleOrderList extends Vue {

        @Ref('saleOrderInvoices')
        public invoicesModal!: SaleOrderInvoices;

        @Ref('integratedWithBysoftBusiness')
        public integratedWithBysoftBusiness: boolean | null = null;

        public orders: SaleOrder[] = [];
        public isPaginationSimple = false;
        public paginationPosition = 'bottom';
        public defaultSortDirection = 'desc';
        public sortIcon = 'arrow-up';
        public sortIconSize = 'is-small';
        public currentPage = 1;
        public perPage = 10;
        public isPaginated = true;

        public async created() {
            this.checkBysoftBusinessIntegration();
        }

        public async checkBysoftBusinessIntegration() {
            if (this.user) {
                if (this.user.tenant && this.user.tenant.isIntegratedWithBysoftBusiness) {
                    this.integratedWithBysoftBusiness = this.user.tenant.isIntegratedWithBysoftBusiness;
                } else if (this.user.endCustomer && this.user.endCustomer.tenant &&
                this.user.endCustomer.tenant.isIntegratedWithBysoftBusiness !== null) {
                    this.integratedWithBysoftBusiness = this.user.endCustomer.tenant.isIntegratedWithBysoftBusiness;
                }

                if (this.integratedWithBysoftBusiness === null) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: `${this.$i18n.t('sale_order.tenant_error')}`,
                        type: 'is-danger',
                    });
                } else {
                    await this.getOrders();
                }
            }
        }

        public reload() {
            this.orders = [];
            this.checkBysoftBusinessIntegration();
            // this.getOrders();
        }

        public get currentUser(): User {
            return this.$store.getters[PersistentGetters.CurrentUser] as User;
        }

        public filterStatus(row: any, input: string) {
            if (input === null || input === undefined) {
                return false;
            }
            const status = row.status;
            if (status === null || status === undefined) {
                return false;
            }
            const localisedType =
                this.$i18n.t(`sale_order.status.${status}`).toString().toLocaleLowerCase();
            const localisedInput = input.toLocaleLowerCase();
            return localisedType.includes(localisedInput);
        }

        public filterDate(row: any, input: string) {
            if (input === null || input === undefined) {
                return false;
            }
            const date = row.date;
            if (date === null || date === undefined) {
                return false;
            }

            const localisedInput = input.toLocaleLowerCase();
            return date.toLocaleDateString().includes(localisedInput);
        }

        public filterAmount(row: any, input: string) {
            if (input === null || input === undefined) {
                return false;
            }
            const amount = row.totalAmount;
            if (amount === null || amount === undefined) {
                return false;
            }

            const localisedInput = input.toLocaleLowerCase();
            return this.$n(amount, 'decimal').includes(localisedInput);
        }

        public resumeQuoteEnabled(status: number): boolean {
            return this.isEndCustomer && status === 10;
        }

        public get user(): User {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isEndCustomer(): boolean {
            return hasEndCustomerScope(this.user);
        }

        private async getOrders() {
            this.$spinner.showSpinner();
            const filter: SaleOrderFilter = new SaleOrderFilter(null, null);
            saleOrdersService.get(filter)
                .then((response) => this.orders = response)
                .finally(() => this.$spinner.removeSpinner());
        }

        private showReport(id: number) {
            this.$spinner.showSpinner();
            saleOrdersService.showReport(id).finally(() => this.$spinner.removeSpinner());
        }

        private showInvoices(id: number) {
            this.invoicesModal.openModal(id, this.integratedWithBysoftBusiness);
        }

        private details(id: number) {
            this.$router.push({ name: Routes.SaleOrder, params: { id: id.toString() } });
        }
    }
