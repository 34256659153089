
import { Component, Vue } from 'vue-property-decorator';
import { PersistentActions, PersistentGetters } from '@/store/persistent/enums';
import { RootActions } from '@/store/enums';
import { usersService } from '@/services/usersService';
import { User } from '@/models/user';

@Component
export default class TermsAgreementModal extends Vue {
    public checkboxValue: boolean = false;

    public htmlTxt: string = '';

    public mounted() {
        this.$spinner.showSpinner();
        usersService
        .getTermsTxt(this.locale)
        .then((html: string) => {
            this.htmlTxt = html;
        })
        .finally(() => this.$spinner.removeSpinner());
    }

    public get user(): User {
        return this.$store.getters[PersistentGetters.CurrentUser];
    }

    public get showTermsAgreement() {
        const persistent = this.user.isAcceptedTermsOfAgreement;
        const session = this.$store.getters.termsAcceptedSession;

        // return !!this.user.tenantId && !persistent && !session;
        return !persistent && !session;
    }

    public async acceptTermAgreement() {
        if (this.checkboxValue) {
            const errorMsg: string | null = null;
            this.$spinner.showSpinner();
            this.$store.dispatch(RootActions.SetTermsAcceptedSession, true);
            try {
                this.$store.dispatch(
                    PersistentActions.SetCurrentUser, { ...this.user, isAcceptedTermsOfAgreement: true });
                await usersService.update(this.user);
            } finally {
                this.$spinner.removeSpinner();
                if (!!errorMsg) {
                    this.$buefy.dialog.alert({
                        message: `<p>${this.$i18n.t('terms.notify_failed')}</p><p><b>${errorMsg}</b></p>`,
                        onConfirm: () => usersService.terms(this.locale),
                    });
                }
            }
        }
        this.$store.dispatch('setTermsAcceptedSession', true);
    }

    public async logout() {
        this.$spinner.showSpinner();
        this.$store.dispatch(PersistentActions.SetCurrentUser, {});
        await this.$auth.logout().finally(() => this.$spinner.removeSpinner());
    }

    public get locale() {
        return this.$i18n.locale;
    }
}
