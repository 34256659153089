export class MaterialFormat {

    public static GetEmpty(): MaterialFormat {
        return new MaterialFormat(0, 0, null, null);
    }

    public static GetCopy(original: MaterialFormat): MaterialFormat {
        return new MaterialFormat(
            original.id,
            original.materialId,
            original.thickness,
            original.cost,
        );
    }

    constructor(
        public id: number,
        public materialId: number,
        public thickness: number | null,
        public cost: number | null,
    ) { }
}
