
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import { AccessLevel, User, UserRole } from '@/models/user';
    import { Routes } from '@/router';
    import Button from '@/components/Button.vue';
    import { PersistentGetters } from '@/store/persistent/enums';

    @Component({
        components: {
            'sc-button': Button,
        },
    })
    export default class ListView extends Vue {

        @Prop({ required: false })
        public entities!: any[];

        @Prop({ required: false })
        public newEntityText!: string;

        @Prop({ required: false })
        public deleteMessage!: string;

        @Prop({ required: false })
        public fieldUsedInDeleteMessage!: string;

        public isPaginationSimple = false;
        public paginationPosition = 'bottom';
        public defaultSortDirection = 'asc';
        public sortIcon = 'arrow-up';
        public sortIconSize = 'is-small';
        public currentPage = 1;
        public perPage = 10;
        public isPaginated = true;
        public routes = Routes;

        public async startCreate() {
            this.$emit('startCreate');
        }

        public async startEdit(entity: any) {
            this.$emit('startEdit', entity);
        }

        public confirmDelete(id: number, recordKeyValue: string): void {
            this.$buefy.dialog.confirm({
                title: this.$i18n.t('dialog.confirm_title').toString(),
                message: this.deleteMessage + ` <b>${recordKeyValue}</b>?`,
                confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
                cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteEntity(id),
            });
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isReadOnly(): boolean {
            return this.currentUser.role === UserRole.Viewer;
        }

        protected deleteEntity(id: number): void {
            // override this in derived class
            this.$emit('deleteEntity', id);
        }
    }
