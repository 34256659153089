
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { UserRole } from '@/models/user';
import { Tenant } from '@/models/tenant';
import { PersistentGetters } from '@/store/persistent/enums';
import { Mode } from '@/store/enums';
import { Markup } from '@/models/markup';
import Button from '@/components/Button.vue';
import MaintainMarkup from '@/components/markups/MaintainMarkup.vue';
import { markupsService } from '@/services/markups.service';
import { tenantsService } from '@/services/tenants.service';

@Component({
  components: {
    'sc-maintain-markup': MaintainMarkup,
    'sc-button': Button,
  },
})
export default class MarkupsWithParent extends Vue {
  @Prop({
    required: false,
    default() {
      return Tenant.GetEmpty();
    },
  })
  public tenant!: Tenant;
  @Ref('maintainMarkup')
  public maintainMarkup!: MaintainMarkup;

  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;

  public markups: Markup[] = [];

  public confirmDelete(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('markup.dialog.delete').toString() + ` <b>${name}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteMarkup(id),
    });
  }

  public async startCreate() {
    this.maintainMarkup.openModalExternal(Mode.Create, Markup.GetEmpty(), this.tenant?.id);
  }

  public async startEdit(markup: Markup) {
    this.$spinner.showSpinner();
    const editableMarkup: Markup = await markupsService.getById(markup.id);
    this.$spinner.removeSpinner();

    this.maintainMarkup.openModalExternal(
        this.isReadOnly ? Mode.View : Mode.Edit,
        editableMarkup,
        this.tenant?.id,
        );
  }

  public async modalClose() {
    await this.getMarkups();
  }

  public async created() {
    await this.getMarkups();
  }

  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isReadOnly(): boolean {
    return this.currentUser.role === UserRole.Viewer;
  }

  private async getMarkups() {
    this.$spinner.showSpinner();
    if (this.tenant && this.tenant.id > 0) {
      this.markups = await tenantsService.getMarkups(this.tenant.id);
    } else {
      this.markups = await markupsService.get();
    }
    this.$spinner.removeSpinner();
  }

  private deleteMarkup(id: number): void {
    this.$spinner.showSpinner();
    markupsService
      .delete(id)
      .then(() => {
        this.markups = this.markups.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }
}
