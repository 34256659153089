
import { RejectReason } from '@/models/rejectReason';
import { PersistentGetters } from '@/store/persistent/enums';
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class RejectReasonSelect extends Vue {
  @Prop({ default: 0 })
  public value!: number;

  @Prop({ default: true })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: RejectReason[] = [];

  public mounted(): void {
    this.getItems();
  }

  public getItems() {
    this.items = this.$store.getters[PersistentGetters.GetRejectReasons] as RejectReason[];
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
