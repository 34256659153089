import axios from 'axios';

class ReportsService {

    public async showReport(url: string) {
        const response = await axios.get(`${url}`, { responseType: 'arraybuffer' });
        const fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const fileType = response.headers['content-type'];
        const file = new File([response.data], fileName, { type: fileType });
        const fileUrl = window.URL.createObjectURL(file);
        window.open(fileUrl, '_blank');
        return response;
    }
}

export const reportsService = new ReportsService();
