
import { Component, Vue } from 'vue-property-decorator';
import { Subsidiary } from '../../models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';
import Button from '../Button.vue';

@Component({
   components: {
     'sc-button': Button,
   },
})
export default class CreateSubsidiary extends Vue {
  public isSwitched = false;

  public form: any = null;
  public subsidiary: Subsidiary = Subsidiary.GetEmpty();
  public modalVisible = false;

  public handleChange(event: any): void {
    this.subsidiary = { ...this.subsidiary, [event.target.name]: event.target.value };
  }

  public openModal() {
    this.subsidiary = Subsidiary.GetEmpty();
    this.modalVisible = true;
  }

  public get isEnabled() {
    if (this.subsidiary && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    subsidiariesService
      .create(this.subsidiary)
      .then(() => {
        this.closeModal();
      })
      .finally(() => this.$spinner.removeSpinner());
  }

  public closeModal() {
    this.modalVisible = false;
    this.$emit('onClose');
  }
}
