import { MaterialFormat } from './materialFormat';

export class Material {

    public static GetEmpty(): Material {
        return new Material(0, 0, null, null, null, null, null, null);
    }

    public static GetCopy(original: Material): Material {
        return new Material(
            original.id,
            original.materialGroupId,
            original.name,
            original.description,
            original.density,
            original.defaultCost,
            original.code,
            original.materialFormats,
        );
    }

    constructor(
        public id: number,
        public materialGroupId: number,
        public name: string | null,
        public description: string | null,
        public density: number | null,
        public defaultCost: number | null,
        public code: string | null,
        public materialFormats: MaterialFormat[] | null,
    ) { }
}
