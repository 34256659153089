
import { Component, Vue } from 'vue-property-decorator';
import Button from '../Button.vue';
import { Invoice } from '../../models/invoice';
import { invoicesService } from '@/services/invoices.service';

@Component({
  components: {
    'sc-button': Button,
  },
})
export default class SaleOrderInvoices extends Vue {
  public saleOrderInvoices: Invoice[] = [];
  public saleOrderInvoicesERP: any[] = [];
  public modalVisible: boolean = false;
  public isIntegratedBysoftBusiness: boolean = false;

    public openModal(id: number, integratedBysoftBusiness: boolean | null): void {
    if (integratedBysoftBusiness) {
      this.isIntegratedBysoftBusiness = integratedBysoftBusiness;
      this.getInvoicesWithBySoftBusiness(id);
    } else {
        this.getInvoices(id);
    }
  }

  private getInvoices(saleOrderId: number) {
    this.$spinner.showSpinner();
    invoicesService
      .get(saleOrderId)
      .then((response) => {
        this.saleOrderInvoices = response;
        if (this.saleOrderInvoices.length === 1) {
          this.showReport(this.saleOrderInvoices[0].id);
          this.modalVisible = false;
          return;
        }
        this.modalVisible = true;
      })
      .finally(() => {
        this.$spinner.removeSpinner();
    });
  }

  private getInvoicesWithBySoftBusiness(quoteId: number) {
    this.$spinner.showSpinner();
    invoicesService
      .get(quoteId)
      .then((response) => {
        this.saleOrderInvoicesERP = response;
        console.log(response);
        if (this.saleOrderInvoicesERP.length === 1) {
          this.showReport(this.saleOrderInvoicesERP[0].id);
          this.modalVisible = false;
          return;
        }
        this.modalVisible = true;
      })
      .finally(() => {
        this.$spinner.removeSpinner();
    });
  }

  private showReport(id: number) {
    this.$spinner.showSpinner();
    invoicesService.showReport(id).finally(() => this.$spinner.removeSpinner());
  }

  private invoiceDescription(invoice: Invoice) {
    return invoice.reference + ' (' + invoice.documentDate.toLocaleDateString() + ')';
  }
}
