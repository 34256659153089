

    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { tenantsService } from '@/services/tenants.service';
    import { Tenant } from '@/models/tenant';
    import { UriPattern } from '@/models/constants';
    import Button from '../Button.vue';

    @Component({
        components: {
            'sc-button': Button,
        },
    })

    export default class MaintainTenantServices extends Vue {

        @Prop({ default: Tenant.GetEmpty() })
        public tenant!: Tenant;

        public editTenant: Tenant = Tenant.GetEmpty();
        public readonly uriPattern: string = UriPattern;

        public created() {
            this.editTenant = Tenant.GetCopy(this.tenant);

            if (this.editTenant && this.editTenant.defaultServicesHost == null) {
                this.editTenant.defaultServicesHost = true;
            }
        }

        public handleChange(): void {
            this.editTenant = Tenant.GetCopy(this.editTenant);
        }

        public async onSubmit() {
            if (this.editTenant.defaultServicesHost) {
                this.editTenant.servicesHost = '';
                this.editTenant.servicesVersion = '';
            }
            this.$spinner.showSpinner();
            await tenantsService.update(this.editTenant);
            this.$emit('updated');
            this.$spinner.removeSpinner();
            const saveSuccess = this.$i18n.t('tenant.confirm.save').toString();
            this.$buefy.toast.open({ message: saveSuccess, type: 'is-success' });
        }

        public get isEnabled() {
            if (this.editTenant && this.$refs.form) {
                const refForm = this.$refs.form as any;
                return refForm.checkValidity();
            }
            return false;
        }
    }

