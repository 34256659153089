
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import Button from '@/components/Button.vue';
    import { Mode } from '@/store/enums';
    import { UserRole } from '@/models/user';
    import { PersistentGetters } from '@/store/persistent/enums';
    import { MaterialGroup } from '../../models/materialGroup';
    import { Tenant } from '../../models/tenant';
    import { Material } from '../../models/material';
    import MaterialFormatsWithParent from './MaterialFormatsWithParent.vue';
    import MaintainMaterial from '@/components/materials/MaintainMaterial.vue';
    import { materialsService } from '../../services/materials.service';
    import { materialGroupsService } from '../../services/materialGroups.service';

    @Component({
        components: {
            'sc-button': Button,
            'sc-formats': MaterialFormatsWithParent,
            'sc-maintain-material': MaintainMaterial,
        },
    })
    export default class MaterialsWithParent extends Vue {

        @Prop({ required: true, default: MaterialGroup.GetEmpty() })
        public materialGroup!: MaterialGroup;

        @Prop({ required: true, default: Tenant.GetEmpty() })
        public tenant!: Tenant;

        public isPaginationSimple = false;
        public paginationPosition = 'bottom';
        public defaultSortDirection = 'asc';
        public sortIcon = 'arrow-up';
        public sortIconSize = 'is-small';
        public currentPage = 1;
        public perPage = 10;
        public isPaginated = true;

        public materials: Material[] = [];

        @Ref('maintainMaterial')
        public maintainMaterial!: MaintainMaterial;

        public async created() {
            await this.getMaterials();
        }

        public async modalClose() {
            await this.getMaterials();
        }

        public async startCreate() {
            this.maintainMaterial.openModalExternal(
                Mode.Create,
                Material.GetEmpty(),
            );
        }

        public async startEdit(material: Material) {
            this.$spinner.showSpinner();
            const editableMaterial: Material = await materialsService.getById(material.id);
            this.$spinner.removeSpinner();

            this.maintainMaterial.openModalExternal(
                this.isReadOnly ? Mode.View : Mode.Edit,
                editableMaterial,
            );
        }

        public confirmDelete(id: number, name: string): void {
            this.$buefy.dialog.confirm({
                title: this.$i18n.t('dialog.confirm_title').toString(),
                message: this.$i18n.t('material.dialog.delete').toString() + ` <b>${name}</b>?`,
                confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
                cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteMaterial(id),
            });
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isReadOnly(): boolean {
            return this.currentUser.role === UserRole.Viewer;
        }

        private async getMaterials() {
            this.$spinner.showSpinner();
            if (this.materialGroup && this.materialGroup.id > 0) {
                this.materials = await materialGroupsService.getMaterials(this.materialGroup.id);
            }
            this.$spinner.removeSpinner();
        }

        private deleteMaterial(materialId: number): void {
            this.$spinner.showSpinner();
            materialsService
                .delete(materialId)
                .then(() => {
                    this.materials = this.materials.filter((x) => x.id !== materialId);
                })
                .finally(() => {
                    this.$spinner.removeSpinner();
                });
        }

        private get currency() {
            return this.tenant.currencyAbbreviation;
        }

        private get materialCostUnit() {
            let weightUnit;
            if (this.tenant.measurement && this.tenant.measurement === '2') {
                weightUnit = this.$i18n.t(`units.weight.pound.abbreviation`);
            } else {
                weightUnit = this.$i18n.t(`units.weight.kilo.abbreviation`);
            }
            return `${this.currency}/${weightUnit}`;
        }

        private get densityUnit() {
            let weightUnit;
            let distanceUnit;
            if (this.tenant.measurement && this.tenant.measurement === '2') {
                weightUnit = this.$i18n.t(`units.weight.pound.abbreviation`);
                distanceUnit = this.$i18n.t(`units.distance.inches.abbreviation`);
            } else {
                weightUnit = this.$i18n.t(`units.weight.gram.abbreviation`);
                distanceUnit = this.$i18n.t(`units.distance.centimeters.abbreviation`);
            }

            return `${weightUnit}/${distanceUnit}³`;
        }
    }
