
import { Component, Prop, Vue } from 'vue-property-decorator';
import RejectReasonSelect from '../inputs/RejectReasonSelect.vue';
import { Quote } from '@/models/quote';
import { quotesService } from '@/services/quotes.service';
import { QuoteStatusEnum } from '@/models/enums/QuoteStatusEnum';

@Component({
  components: {
    'sc-reject-reason-select': RejectReasonSelect,
  },
})
export default class RejectQuote extends Vue {
  public editableQuoteId: number = 0;

  public quote: Quote = Quote.GetEmpty();

  public form: any = null;
  public isComponentModalActive = false;
  public dateTimeSegment: string | undefined = undefined;

  public getItems() {
    return;
  }

  public get isEnabled() {
    if (this.quote && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public handleChange(event: any): void {
    this.quote = { ...this.quote, [event.target.name]: event.target.value };
  }

  public async openModal(quoteId: number): Promise<void> {
    this.editableQuoteId = quoteId;
    this.$spinner.showSpinner();
    await this.initModal();
    this.$spinner.removeSpinner();

    this.isComponentModalActive = true;
  }

  public async onSubmit() {

    this.rejectQuoteWithoutERP();
    // if (this.quote && this.quote.isIntegratedWithERPSystem) {
    //  this.rejectQuoteERP();
    // } else {
    //  this.rejectQuoteWithoutERP();
    // }
  }

  public async rejectQuoteERP() {
    this.isComponentModalActive = false;
    this.$buefy.toast.open({
      duration: 5000,
      message: `${this.$i18n.t('no_available_errors.temporal_no_available')}`,
      type: 'is-danger',
    });
  }

  public async rejectQuoteWithoutERP() {
    // this.quote.status = QuoteStatusEnum.Rejected;
    this.$spinner.showSpinner();
    quotesService.updateStatus(this.quote.id, QuoteStatusEnum.Rejected, 1)
      .then((response) => {
        this.$emit('rejected');
      })
      .catch((error) => {
        this.$buefy.toast.open({
          duration: 5000,
          message: `${this.$i18n.t('business_central.reject_order_failure')}`,
          type: 'is-danger',
        });
      })
      .finally(() => {
        this.isComponentModalActive = false;
        this.$spinner.removeSpinner();
      });
  }

  private async initModal() {
    quotesService.getById(this.editableQuoteId).then((data) => (this.quote = data));
    this.isComponentModalActive = true;
  }
}
