
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class BySoftBysunessQuoteQuote extends Vue {
  public importingAction: boolean | null = null;

  public form: any = null;
  public isComponentModalActive = false;

  public async openModal(isImporting: boolean): Promise<void> {
    this.importingAction = isImporting;
    this.$spinner.showSpinner();
    this.isComponentModalActive = true;
    this.$spinner.removeSpinner();

    this.isComponentModalActive = true;
  }

  public async onSubmit() {
    this.$emit('accepted');
  }
}
