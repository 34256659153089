
import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Mode } from '@/store/enums';
    import { PersistentGetters } from '@/store/persistent/enums';
    import Button from '../Button.vue';
    import { AccessLevel } from '@/models/user';
    import { hasPermission } from '@/utils/permissionUtils';
    import { materialFormatsService } from '@/services/materialFormats.service';
    import { MaterialFormat } from '../../models/materialFormat';
    import { Tenant } from '../../models/tenant';
    import ValidationField from '@/components/inputs/ValidationField.vue';

    @Component({
        components: {
            'sc-button': Button,
            'sc-validation-field': ValidationField,
        },
    })
    export default class MaintainMaterialFormat extends Vue {

        @Prop({ required: true, default: 0 })
        public materialId!: number;

        @Prop({ required: true, default: Tenant.GetEmpty() })
        public tenant!: Tenant;

        public format: MaterialFormat = MaterialFormat.GetEmpty();

        public mode: Mode = Mode.View;

        public form: any = null;
        public modalVisible = false;

        public isEditMode: boolean = this.mode === Mode.Edit;
        public isCreateMode: boolean = this.mode === Mode.Create;
        public isViewMode: boolean = this.mode === Mode.View;

        public get actionTitle() {
            if (this.isCreateMode) {
                return this.$i18n.t('action.create').toString();
            } else if (this.isEditMode) {
                if (this.isReadOnly) {
                    return this.$i18n.t('action.view').toString();
                } else {
                    return this.$i18n.t('action.edit').toString();
                }
            } else {
                return this.$i18n.t('action.close').toString();
            }
        }

        public get isEnabled() {
            if (this.format && this.$refs.form) {
                const refForm = this.$refs.form as any;
                return refForm.checkValidity();
            }
            return false;
        }

        public handleChange(event: any): void {
            this.format = { ...this.format, [event?.target.name]: event?.target.value };
        }

        public async openModalExternal(mode: Mode, editableFormat: MaterialFormat): Promise<void> {
            if (mode === Mode.Create) {
                this.format = MaterialFormat.GetEmpty();
                this.format.materialId = this.materialId;
            } else if (mode === Mode.Edit) {
                this.format = MaterialFormat.GetCopy(editableFormat as MaterialFormat);
            } else {
                this.format = editableFormat as MaterialFormat;
            }

            this.mode = mode;
            this.updateMode();
            await this.openModal();
        }

        public async onSubmit() {
            this.$spinner.showSpinner();

            if (this.isCreateMode) {
                materialFormatsService
                    .create(this.format)
                    .then(() => {
                        this.modalVisible = false;
                        this.$emit('onClose');
                    })
                    .finally(() => this.$spinner.removeSpinner());
            } else if (this.isEditMode) {
                materialFormatsService
                    .update(this.format)
                    .then(() => {
                        this.modalVisible = false;
                        this.$emit('onClose');
                    })
                    .finally(() => this.$spinner.removeSpinner());
            }
        }

        private updateMode() {
            this.isEditMode = this.mode === Mode.Edit;
            this.isCreateMode = this.mode === Mode.Create;
            this.isViewMode = this.mode === Mode.View;
        }

        private async openModal(): Promise<void> {
            this.modalVisible = true;
        }

        public get isReadOnly(): boolean {
            return !(this.isSuperAdmin || this.isTenantAdmin);
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isSuperAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
        }

        public get isTenantAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
        }

        private get thicknessLabel() {
            return `${this.$i18n.t(`format.thickness`)} (${this.thicknessUnit})`;
        }

        private get thicknessUnit() {
            if (this.tenant.measurement && this.tenant.measurement === '2') {
                return this.$i18n.t(`units.distance.inches.abbreviation`);
            } else {
                return this.$i18n.t(`units.distance.millimeters.abbreviation`);
            }
        }

        private get currency() {
            return this.tenant.currencyAbbreviation;
        }

        private get costLabel() {
            let weightUnit;
            if (this.tenant.measurement && this.tenant.measurement === '2') {
                weightUnit = this.$i18n.t(`units.weight.pound.abbreviation`);
            } else {
                weightUnit = this.$i18n.t(`units.weight.kilo.abbreviation`);
            }
            return `${this.$i18n.t(`format.cost.label`)} ${this.currency}/${weightUnit}`;
        }

    }
