import RootState from '@/store/rootState';

const state: RootState = {
  reportsMenu: [],
  spinners: 0,
  termsAcceptedSession: false,
  userInfoLoaded: false,
};

export default state;
