import { MaterialConsumptionPolicyEnum } from './enums/MaterialConsumptionPolicyEnum';
import { TechnologyTypeEnum } from './enums/TechnologyTypeEnum';

export class Technology {
    public static GetEmpty(): Technology {
        return new Technology(
            0,
            0,
            null,
            null,
            null,
            null,
            null,
        );
    }

    public static GetCopy(original: Technology): Technology {
        return new Technology(
            original.id,
            original.tenantId,
            original.technologyName,
            original.technologyType,
            original.hourRate,
            original.serviceId,
            original.materialConsumptionPolicy,
        );
    }

    constructor(
        public id: number,
        public tenantId: number,
        public technologyName: string | null,
        public technologyType: TechnologyTypeEnum | null,
        public hourRate: number | null,
        public serviceId: string | null,
        public materialConsumptionPolicy: MaterialConsumptionPolicyEnum | null,
    ) {}
}
