import state from '@/store/quote/state';
import actions from '@/store/quote/actions';
import mutations from '@/store/quote/mutations';
import getters from '@/store/quote/getters';

export default {​​
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}​​;
