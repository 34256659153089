
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PersistentGetters } from '@/store/persistent/enums';
import { CurrencyCode } from '@/models/currencyCode';
@Component
export default class CurrencyCodesSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: CurrencyCode[] = [];

  public mounted(): void {
    this.items = this.getItems();
  }
  public getItems() {
    return this.$store.getters[PersistentGetters.GetCurrencyCodes] as CurrencyCode[];
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
