
import { Component, Prop } from 'vue-property-decorator';
import { Mode } from '@/store/enums';
import ModalForm from '@/components/ModalForm.vue';
import { addressesService } from '@/services/addresses.service';
import { Address } from '../../models/address';
import { AddressTypeEnum } from '../../models/enums/AddressTypeEnum';
import ShippingsSelect from '@/components/inputs/ShippingsSelect.vue';
import AddressTypeSelect from '@/components/inputs/AddressTypeSelect.vue';
import { EndCustomer } from '@/models/endCustomer';
import { endCustomersService } from '@/services/endCustomers.service';

@Component({
    components: {
        'sc-modal-form': ModalForm,
        'sc-shippings-select': ShippingsSelect,
        'sc-address-type-select': AddressTypeSelect,
    },
})
export default class MaintainAddress extends ModalForm {

    @Prop({ required: true, default: 0 })
    public endCustomerId!: number;

    public address: Address = Address.GetEmpty();

    public endCustomer: EndCustomer = EndCustomer.GetEmpty();

    public addressTypes = Object.keys(AddressTypeEnum)
        .filter((item) => {
            const value = Number(item);
            return !isNaN(value);
        })
        .map((item) => Number(item));

    public get isEnabled() {
        if (this.address && this.$refs.form) {
            const refForm = this.$refs.form as any;
            return refForm.checkValidity();
        }
        return false;
    }

    public get isShippingAddress() {
        return this.address.type === AddressTypeEnum.ShippingAddress;
    }

    public async openModalExternal(mode: Mode, editableAddress: Address): Promise<void> {
        if (mode === Mode.Create) {
            this.address = Address.GetEmpty();
            this.address.endCustomerId = this.endCustomerId;
            await endCustomersService.getById(this.endCustomerId).then((response) => {
                this.endCustomer = response;
            });
        } else if (mode === Mode.Edit) {
            this.address = Address.GetCopy(editableAddress as Address);
            await endCustomersService.getById(this.address.endCustomerId).then((response) => {
                this.endCustomer = response;
            });
        } else {
            this.address = editableAddress as Address;
        }

        this.updateMode(mode);
        this.toggleModal(true);
    }

    public async saveAddress() {
        this.$spinner.showSpinner();

        if (this.isCreateMode) {
            addressesService
                .create(this.address)
                .then(() => {
                    this.toggleModal(false);
                    this.$emit('onClose');
                })
                .finally(() => this.$spinner.removeSpinner());
        } else if (this.isEditMode) {
            addressesService
                .update(this.address)
                .then(() => {
                    this.toggleModal(false);
                    this.$emit('onClose');
                })
                .finally(() => this.$spinner.removeSpinner());
        }
    }

    public cancelSave() {
        this.toggleModal(false);
    }

    public handleChange(event: any): void {
        this.address = { ...this.address, [event?.target.name]: event?.target.value };
    }
}
