
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class QuoteElement extends Vue {
  @Prop({ required: false })
  public image!: string;

  @Prop({ required: false })
  public icon!: string;

  @Prop({ required: true })
  public label!: string;

  @Prop({ required: false, default: false })
  public invalid!: boolean;

  @Prop({ required: false, default: false })
  public error!: boolean;

  @Prop({required: false, default: false})
  public collapsed!: boolean;

  @Prop({required: false, default: false})
  public isCheckSelected!: boolean;

  public selectedChekboxChanged() {
    this.$emit('selectedPartChanged');
  }
}
