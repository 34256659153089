import { SaleOrderStatusEnum } from './enums/SaleOrderStatusEnum';

export class SaleOrder {
  public static GetEmpty(): SaleOrder {
      return new SaleOrder(0, 0, '', '', SaleOrderStatusEnum.Unknown, '', 0, '', false, false, new Date());
  }
  constructor(
    public id: number,
    public quoteId: number,
    public saleOrderReference: string,
    public quoteReference: string,
    public status: SaleOrderStatusEnum,
    public endCustomerName: string,
    public totalAmount: number,
    public currencySymbol: string,
    public hasInvoices: boolean,
    public hasReport: boolean,
    public date: Date,
  ) {}
}
