
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { ValidationProvider } from 'vee-validate';

    @Component({
        components: {
            'sc-validation': ValidationProvider,
        },
    })
    export default class ValidationField extends Vue {
        @Prop({ default: '' })
        public label!: string;

        @Prop({ default: '' })
        public rules!: string;

        @Prop({ default: '' })
        public fieldClass!: string;
    }
