
import { Component, Prop, Vue } from 'vue-property-decorator';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Subsidiary } from '@/models/subsidiary';
import Button from '@/components/Button.vue';
import { UserRole } from '@/models/user';
import { PersistentGetters } from '@/store/persistent/enums';

@Component({
  components: {
    'sc-button': Button,
  },
})
export default class SubsidiaryProperties extends Vue {
  @Prop({ required: true, default: Subsidiary.GetEmpty() })
  public subsidiary!: Subsidiary;

  public editSubsidiary: Subsidiary = Subsidiary.GetEmpty();

  public created() {
    this.editSubsidiary = Subsidiary.GetCopy(this.subsidiary);
  }

  public handleChange(): void {
    this.editSubsidiary = Subsidiary.GetCopy(this.editSubsidiary);
  }

  public get isEnabled() {
    if (this.editSubsidiary && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    subsidiariesService.update(this.editSubsidiary).finally(() => {
      this.$emit('updated');
      this.$spinner.removeSpinner();
    });
  }

  public get isReadOnly(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role === UserRole.Viewer;
  }
}
