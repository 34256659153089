export enum RootMutations {
  SetReportsMenu = 'setReportsMenu',
  ShowSpinner = 'showSpinner',
  RemoveSpinner = 'removeSpinner',
  SetTermsAcceptedSession = 'setTermsAcceptedSession',
  SetUserInfoLoaded = 'setUserInfoLoaded',
}

export enum RootActions {
  SetReportsMenu = 'setReportsMenu',
  ShowSpinner = 'showSpinner',
  RemoveSpinner = 'removeSpinner',
  SetTermsAcceptedSession = 'setTermsAcceptedSession',
  SetUserInfoLoaded = 'setUserInfoLoaded',
}

export enum RootGetters {
  GetReportsMenu = 'getReportsMenu',
  Spinners = 'spinners',
  TermsAcceptedSession = ' termsAcceptedSession',
  UserInfoLoaded = 'userInfoLoaded',
}

export enum Mode {
  Create = 'create',
  Edit = 'edit',
  View = 'view',
}
