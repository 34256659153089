import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const messages = {
  en: Object.assign(
    {},
    require('./locale/en/en.json'),
    require('./locale/en/timezones.json'),
    require('./locale/en/currencies.json'),
  ),
  es: Object.assign(
    {},
    require('./locale/es/es.json'),
    require('./locale/es/timezones.json'),
    require('./locale/en/currencies.json'),
  ),
  fr: Object.assign(
    {},
    require('./locale/fr/fr.json'),
    require('./locale/fr/timezones.json'),
    require('./locale/fr/currencies.json'),
    ),
  de: Object.assign(
    {},
    require('./locale/de/de.json'),
    require('./locale/de/timezones.json'),
    require('./locale/de/currencies.json'),
  ),
  it: Object.assign(
    {},
    require('./locale/it/it.json'),
    require('./locale/it/timezones.json'),
    require('./locale/it/currencies.json'),
  ),
  dan: Object.assign(
    {},
    require('./locale/dan/dan.json'),
    require('./locale/dan/timezones.json'),
    require('./locale/dan/currencies.json'),
  ),
  nl: Object.assign(
    {},
    require('./locale/nl/nl.json'),
    require('./locale/nl/timezones.json'),
    require('./locale/nl/currencies.json'),
  ),
  kor: Object.assign(
    {},
    require('./locale/kor/kor.json'),
    require('./locale/kor/timezones.json'),
    require('./locale/kor/currencies.json'),
  ),
  pol: Object.assign(
    {},
    require('./locale/pol/pol.json'),
    require('./locale/pol/timezones.json'),
    require('./locale/pol/currencies.json'),
  ),
  cn: Object.assign(
    {},
    require('./locale/cn/cn.json'),
    require('./locale/cn/timezones.json'),
    require('./locale/cn/currencies.json'),
  ),
};

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  it: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  dan: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  kor: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  pol: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  cn: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
};

const navigatorLanguage = window.navigator.language || 'en';
const navigatorCode = navigatorLanguage.split('-');

export default new VueI18n({
  locale: navigatorCode[0] || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages,
    numberFormats,
});
