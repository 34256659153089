
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import SubsidiariesSelect from '../inputs/SubsidiariesSelect.vue';
    import TenantsSelect from '../inputs/TenantsSelect.vue';
    import { Mode } from '@/store/enums';
    import { PersistentGetters } from '@/store/persistent/enums';
    import Button from '../Button.vue';
    import { AccessLevel, User, UserRole, UserType } from '@/models/user';
    import { hasPermission } from '@/utils/permissionUtils';
    import EndCustomerSelect from '../inputs/EndCustomerSelect.vue';
    import { TechnologyTypeEnum } from '../../models/enums/TechnologyTypeEnum';
    import { MaterialConsumptionPolicyEnum } from '../../models/enums/MaterialConsumptionPolicyEnum';
    import { UriPattern } from '@/models/constants';
    import { Technology } from '@/models/technology';
    import { Tenant } from '@/models/tenant';
    import { technologiesService } from '@/services/technologies.service';
    import ValidationField from '@/components/inputs/ValidationField.vue';

    @Component({
        components: {
            'sc-subsidiaries-select': SubsidiariesSelect,
            'sc-tenants-select': TenantsSelect,
            'sc-end-customers-select': EndCustomerSelect,
            'sc-button': Button,
            'sc-validation-field': ValidationField,
        },
    })
    export default class MaintainTechnology extends Vue {

        @Prop({ required: true, default: Tenant.GetEmpty() })
        public tenant!: Tenant;

        public readonly uriPattern: string = UriPattern;

        public technology: Technology = Technology.GetEmpty();

        public mode: Mode = Mode.View;

        public form: any = null;
        public modalVisible = false;

        public isEditMode: boolean = this.mode === Mode.Edit;
        public isCreateMode: boolean = this.mode === Mode.Create;
        public isViewMode: boolean = this.mode === Mode.View;

        public technologyTypes = Object.keys(TechnologyTypeEnum)
            .filter((item) => {
                const value = Number(item);
                return !isNaN(value);
            })
            .map((item) => Number(item));

        public materialConsumptionPolicies = Object.keys(MaterialConsumptionPolicyEnum)
            .filter((item) => {
                const value = Number(item);
                return !isNaN(value);
            })
            .map((item) => Number(item));

        public get actionTitle() {
            if (this.isCreateMode) {
                return this.$i18n.t('action.create').toString();
            } else if (this.isEditMode) {
                if (this.isReadOnly) {
                    return this.$i18n.t('action.view').toString();
                } else {
                    return this.$i18n.t('action.edit').toString();
                }
            } else {
                return this.$i18n.t('action.close').toString();
            }
        }

        public get isEnabled() {
            if (this.technology && this.$refs.form) {
                const refForm = this.$refs.form as any;
                return refForm.checkValidity();
            }
            return false;
        }

        public get isCuttingTechnology() {
            return this.technology.technologyType === 0;
        }

        public handleChange(event: any): void {
            this.technology = { ...this.technology, [event?.target.name]: event?.target.value };
        }

        public techTypeChanged() {
            this.technology.materialConsumptionPolicy = null;
        }

        public async openModalExternal(mode: Mode, editableTech: Technology): Promise<void> {
            if (mode === Mode.Create) {
                this.technology = Technology.GetEmpty();
                this.technology.tenantId = this.tenantId;
            } else if (mode === Mode.Edit) {
                this.technology = Technology.GetCopy(editableTech as Technology);
            } else {
                this.technology = editableTech as Technology;
            }

            this.mode = mode;
            this.updateMode();
            await this.openModal();
        }

        public async onSubmit() {
            this.$spinner.showSpinner();

            if (this.isCreateMode) {
                technologiesService
                    .create(this.technology)
                    .then(() => {
                        this.modalVisible = false;
                        this.$emit('onClose');
                    })
                    .finally(() => this.$spinner.removeSpinner());
            } else if (this.isEditMode) {
                technologiesService
                    .update(this.technology)
                    .then(() => {
                        this.modalVisible = false;
                        this.$emit('onClose');
                    })
                    .finally(() => this.$spinner.removeSpinner());
            }
        }

        private updateMode() {
            this.isEditMode = this.mode === Mode.Edit;
            this.isCreateMode = this.mode === Mode.Create;
            this.isViewMode = this.mode === Mode.View;
        }

        private async openModal(): Promise<void> {
            this.modalVisible = true;
        }

        public get isReadOnly(): boolean {
            return !(this.isSuperAdmin || this.isTenantAdmin);
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isSuperAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
        }

        public get isSubsidiaryAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.SubsidiaryAdmin);
        }

        public get isTenantAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
        }

        public get isEndCustomerAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.EndCustomerAdmin);
        }

        private get tenantId() {
            return this.tenant.id;
        }

        private get hourRateLabel() {
            return `${this.$i18n.t(`technology.hour_rate.label`)} (${this.tenant.currencyAbbreviation}/${this.$i18n.t(`units.time.hour.abbreviation`)})`;
        }
    }
