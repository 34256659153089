
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { AccessLevel, User, UserRole } from '@/models/user';
import { Tenant } from '@/models/tenant';
import { Routes } from '@/router';
import { endCustomersService } from '@/services/endCustomers.service';
import Button from '@/components/Button.vue';
import { PersistentGetters } from '@/store/persistent/enums';
import { Mode } from '@/store/enums';
import { EndCustomer } from '@/models/endCustomer';
import MaintainEndCustomerModal from '@/components/endCustomers/MaintainEndCustomerModal.vue';

@Component({
  components: {
    'sc-maintain-end-customer-modal': MaintainEndCustomerModal,
    'sc-button': Button,
  },
})
export default class EndCustomersWithParent extends Vue {
  @Prop({ required: false, default: Tenant.GetEmpty() })
  public tenant!: Tenant;

  @Ref('maintainEndCustomerModal')
  public maintainEndCustomerModal!: MaintainEndCustomerModal;

  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;
  public routes = Routes;

  public endCustomers: EndCustomer[] = [];
  public roleTranslations: any[] = [];

  public confirmDelete(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('end_customer.dialog.delete').toString() + ` <b>${name}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteEndCustomer(id),
    });
  }

  public async startCreate() {
    this.maintainEndCustomerModal.openModalCreate();
  }

  public async startEdit(endCustomer: EndCustomer) {
    this.$spinner.showSpinner();
    const editableEndCustomer: EndCustomer = await endCustomersService.getById(endCustomer.id);
    this.$spinner.removeSpinner();

    this.maintainEndCustomerModal.openModalEdit(editableEndCustomer);
    // this.isReadOnly ? Mode.View : Mode.Edit,
  }

  public async modalClose() {
    await this.getEndCustomers();
  }

  public async created() {
    await this.getEndCustomers();
  }

  private async getEndCustomers() {
    this.$spinner.showSpinner();
    if (this.tenant && this.tenant.id > 0) {
      this.endCustomers = await endCustomersService.getByTenant(this.tenant.id);
    } else {
      this.endCustomers = await endCustomersService.get();
    }
    this.$spinner.removeSpinner();
  }

  private deleteEndCustomer(id: number): void {
    this.$spinner.showSpinner();
    endCustomersService
      .delete(id)
      .then(() => {
        this.endCustomers = this.endCustomers.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  public get isReadOnly(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role === UserRole.Viewer;
  }

  public get showParentColumns(): boolean {
    return !(this.tenant?.id > 0);
  }
}
