
    import { Component, Ref, Vue } from 'vue-property-decorator';
    import { Routes } from '@/router';
    import Button from '@/components/Button.vue';
    import { User } from '@/models/user';
    import { hasEndCustomerScope } from '@/utils/permissionUtils';
    import { PersistentGetters } from '@/store/persistent/enums';
    import { quotesService } from '@/services/quotes.service';
    import { QuoteSummary } from '@/models/quoteSummary';
    import { QuoteFilter } from '@/models/QuoteFilter';

    @Component({
        components: {
            'sc-button': Button,
        },
    })
    export default class QuoteList extends Vue {

        public quotes: QuoteSummary[] = [];
        public isPaginationSimple = false;
        public paginationPosition = 'bottom';
        public defaultSortDirection = 'desc';
        public sortIcon = 'arrow-up';
        public sortIconSize = 'is-small';
        public currentPage = 1;
        public perPage = 10;
        public isPaginated = true;
        public routes = Routes;

        @Ref('integratedWithBysoftBusiness')
        public integratedWithBysoftBusiness: boolean | null = null;

        public async created() {
            await this.getQuotes();
        }

        public reload() {
            this.quotes = [];
            this.getQuotes();
        }

        public get currentUser(): User {
            return this.$store.getters[PersistentGetters.CurrentUser] as User;
        }

        public filterStatus(row: any, input: string) {
            if (input === null || input === undefined) {
                return false;
            }
            const status = row.status;
            if (status === null || status === undefined) {
                return false;
            }
            const localisedType =
                this.$i18n.t(`quote_summary_card.status.${status}`).toString().toLocaleLowerCase();
            const localisedInput = input.toLocaleLowerCase();
            return localisedType.includes(localisedInput);
        }

        public filterDate(row: any, input: string) {
            if (input === null || input === undefined) {
                return false;
            }
            const date = row.date;
            if (date === null || date === undefined) {
                return false;
            }

            const localisedInput = input.toLocaleLowerCase();
            return date.toLocaleDateString().includes(localisedInput);
        }

        public filterAmount(row: any, input: string) {
            if (input === null || input === undefined) {
                return false;
            }
            const amount = row.totalAmount;
            if (amount === null || amount === undefined) {
                return false;
            }

            const localisedInput = input.toLocaleLowerCase();
            return this.$n(amount, 'decimal').includes(localisedInput);
        }

        public resumeQuoteEnabled(status: number): boolean {
            return this.isEndCustomer && status === 10;
        }

        public get user(): User {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isEndCustomer(): boolean {
            return hasEndCustomerScope(this.user);
        }

        private async getQuotes() {
            this.$spinner.showSpinner();
            const filter: QuoteFilter = new QuoteFilter(null, null);
            quotesService.get(filter)
                .then((response) => this.quotes = response)
                .finally(() => this.$spinner.removeSpinner());
        }

        private resume(id: number) {
            this.$router.push({ name: Routes.QuoteParent, params: { id: id.toString() } });
        }

        private details(id: number) {
            this.$router.push({ name: Routes.QuoteParent, params: { id: id.toString() } });
        }

        private showReport(id: number) {
            if (this.user) {
                if (this.user.tenant && this.user.tenant.isIntegratedWithBysoftBusiness) {
                    this.integratedWithBysoftBusiness = this.user.tenant.isIntegratedWithBysoftBusiness;
                } else if (this.user.endCustomer && this.user.endCustomer.tenant &&
                this.user.endCustomer.tenant.isIntegratedWithBysoftBusiness !== null) {
                    this.integratedWithBysoftBusiness = this.user.endCustomer.tenant.isIntegratedWithBysoftBusiness;
                }

                if (this.integratedWithBysoftBusiness === null) {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: `${this.$i18n.t('sale_order.tenant_error')}`,
                        type: 'is-danger',
                    });
                } else {
                    this.showReportConfirmation(id);
                }
            }
        }

        private async showReportConfirmation(id: number) {
            this.$spinner.showSpinner();

            if (this.integratedWithBysoftBusiness) {
                quotesService.showReport(id)
                    .then((response) => {
                        const resp: any = response;
                    })
                    .catch((error) => {
                        if (error.response.status === 400) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: `${this.$i18n.t('sale_order.report_not_found')}`,
                                type: 'is-danger',
                            });
                        } else {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: `${this.$i18n.t('sale_order.show_report_error')}`,
                                type: 'is-danger',
                            });
                        }
                    })
                    .finally(() => {
                        this.$spinner.removeSpinner();
                });
            } else {
                quotesService.showReport(id).finally(() => this.$spinner.removeSpinner());
            }
        }
    }
