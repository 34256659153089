
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MaterialGroup } from '@/models/materialGroup';
import { PersistentGetters } from '@/store/persistent/enums';
import { Material, MaterialFormat, Technology } from '@/models/QuotePart';
import { Measurement } from '@/models/measurement';
import { TechnologyTypeEnum } from '../../models/enums/TechnologyTypeEnum';

@Component({
  components: {

  },
})
export default class EditQuoteParts extends Vue {
  @Prop({ default: false })
  public isModal!: boolean;

  @Prop({ default: false })
  public selectedParts!: string;

  @Prop({ default: null })
  public materialGroupId!: number | null;

  @Prop({ default: null })
  public materialId!: number | null;

  @Prop({ default: null })
  public thicknessId!: number | null;

  @Prop({ default: null })
  public units!: number | null;

  @Prop({ default: null })
  public measurementUnit!: number | null;

  @Prop({ default: null })
  public selectedCustomTechnologies: Technology[] = [];

  @Prop({ default: false })
  public technologiesToChange!: boolean;

  public possibleMaterials: Material[] = [];
  public possibleMaterialFormats: MaterialFormat[] = [];
  public technologiesCustom: Technology[] =
      this.technologies
          .filter((t) => t.technologyType === TechnologyTypeEnum.Custom);
  public items: Measurement[] = this.getItems();

  public materialGroupChanged() {
    const selectedMaterialGroup = this.materialGroups.filter((m) => m.id === this.materialGroupId);
    if (selectedMaterialGroup.length <= 0) {
      this.possibleMaterials = [];
      this.possibleMaterialFormats = [];
      return;
    }

    const groupMaterials = selectedMaterialGroup[0].materials;
    if (groupMaterials && groupMaterials !== null) {
      this.possibleMaterials = groupMaterials as unknown as Material[];
      this.possibleMaterialFormats = [];
      this.materialId = null;
      this.thicknessId = null;
      return;
    }

    this.possibleMaterials = [];
  }

  public materialChanged() {
    const selectedMaterial = this.possibleMaterials.filter((m) => m.id === this.materialId);
    if (selectedMaterial.length <= 0) {
      this.possibleMaterialFormats = [];
      return;
    }

    const materialMaterialFormats = selectedMaterial[0].materialFormats;
    if (materialMaterialFormats && materialMaterialFormats !== null) {
      this.possibleMaterialFormats = materialMaterialFormats;
      this.thicknessId = null;
      return;
    }

    this.possibleMaterialFormats = [];
  }

  public get actionTitle() {
    return this.$i18n.t('quotes.edit_selected_parts.edit_selected_parts_title').toString();
  }

  public get isEnabled() {
    if (this.units && this.units < 1) { return false; }
    return true;
  }

  public async onSubmit() {
    this.$emit('onSubmit', this.materialGroupId, this.materialId, this.thicknessId, this.units,
      this.measurementUnit, this.technologiesToChange , this.selectedCustomTechnologies);
  }

  public closeModal() {
      this.$emit('onClose');
  }

  public get materialGroups(): MaterialGroup[] {
    return this.$store.getters[PersistentGetters.GetMaterialGroups] ?? [];
  }

  public get technologies(): Technology[] {
    return this.$store.getters[PersistentGetters.GetTechnologies] ?? [];
  }

  public getItems() {
    return this.$store.getters[PersistentGetters.GetMeasurements] as Measurement[];
  }

  public get hasMaterials(): boolean {
    return this.possibleMaterials && this.possibleMaterials.length > 0;
  }

  public get hasMaterialFormats(): boolean {
    return this.possibleMaterialFormats && this.possibleMaterialFormats.length > 0;
  }
}
