
import { QuoteStatusEnum } from '@/models/enums/QuoteStatusEnum';
import { QuoteSummary } from '@/models/quoteSummary';
import { Tenant } from '@/models/tenant';
import { User } from '@/models/user';
import { Routes } from '@/router';
import { PersistentGetters } from '@/store/persistent/enums';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '../Button.vue';

@Component({
  components: {
    'sc-button': Button,
  },
})
export default class QuoteSummaryCard extends Vue {
  @Prop({})
  private item!: QuoteSummary;

  private quoteIcon(): string {
    switch (this.item.status) {
      case QuoteStatusEnum.Draft:
        return 'note-edit-outline';
      case QuoteStatusEnum.Accepted:
        return 'check-decagram';
      case QuoteStatusEnum.Rejected:
        return 'note-off-outline';
      default:
        return '';
    }
  }

  private get user(): User {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  private discardQuote(quote: QuoteSummary): void {
    this.$emit('onDiscard', this.item.id);
  }

  private resume() {
    this.$router.push({ name: Routes.QuoteParent, params: { id: this.item.id.toString() } });
  }

  private details() {
    this.$router.push({ name: Routes.QuoteParent, params: { id: this.item.id.toString() } });
  }
}
