export enum QuoteMutations {
  AddPart = 'addPart',
  UpdatePart = 'updatePart',
  RemovePart = 'removePart',
  ResetParts = 'resetParts',
  SetQuote = 'setQuote',
  SetPartIsCollapsed = 'setPartIsCollapsed',
  RemoveParts = 'removeParts',
}

export enum QuoteActions {
  AddPart = 'quote/addPart',
  UpdatePart = 'quote/updatePart',
  RemovePart = 'quote/removePart',
  ResetParts = 'quote/resetParts',
  SetQuote = 'quote/setQuote',
  SetPartIsCollapsed = 'quote/setPartIsCollapsed',
  RemoveParts = 'quote/removeParts',
}

export enum QuoteGetters {
  GetQuote = 'quote/getQuote',
}
