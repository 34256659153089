import { MutationTree } from 'vuex';
import RootState from '@/store/rootState';
import { ReportsMenu } from '@/models/menu';

const mutations: MutationTree<RootState> = {
  setReportsMenu(state: RootState, value: ReportsMenu[]) {
    state.reportsMenu = value;
  },
  showSpinner(state: RootState) {
    state.spinners++;
  },
  removeSpinner(state: RootState) {
    if (state.spinners > 0) {
      state.spinners--;
    }
  },
  setTermsAcceptedSession(state: RootState, termsAcceptedSessionValue: boolean) {
    state.termsAcceptedSession = termsAcceptedSessionValue;
  },
  setUserInfoLoaded(state: RootState, userInfoLoadedValue: boolean) {
      state.userInfoLoaded = userInfoLoadedValue;
  },
};

export default mutations;
