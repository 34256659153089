import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { reportsService } from './reports.service';
import { Invoice } from '../models/invoice';

class InvoicesService {

    public get(id: number): Promise<Invoice[]> {
        return axios
            .get<Invoice[]>(`${BaseUrl}/invoices/${id}`)
            .then((response: any) => {
                responseHandler.handleDates(response.data);
                return response.data;
            });
    }

    public async showReport(id: number) {
        const url = `${BaseUrl}/invoices/${id}/report`;
        return await reportsService.showReport(url);
    }
}

export const invoicesService = new InvoicesService();
