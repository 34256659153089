import { QuotePart } from '@/models/QuotePart';
import { ActionContext, ActionTree } from 'vuex';
import { QuoteMutations } from './enums';
import RootState from '../rootState';
import QuoteState from './quoteState';
import { Quote } from '@/models/quote';

const actions: ActionTree<QuoteState, RootState> = {
  setQuote: (store: ActionContext<QuoteState, RootState>, quote: Quote) => {

    if (quote.parts) {
      quote.parts.forEach((part) => {
        let isCollapsed: boolean = false;

        if (store.state.quote && store.state.quote.parts &&
          store.state.quote.parts.length > 0 && store.state.quote.parts[0].isCollapsed) {
          isCollapsed = true;
        }

        part.isCollapsed = isCollapsed;
      });
    }

    store.commit(QuoteMutations.SetQuote, quote);
  },
  addPart: (store: ActionContext<QuoteState, RootState>, parts: QuotePart[]) => {
    store.commit(QuoteMutations.AddPart, parts);
  },
  resetParts: (store: ActionContext<QuoteState, RootState>) => {
    store.commit(QuoteMutations.ResetParts);
  },
  updatePart: (store: ActionContext<QuoteState, RootState>, part: QuotePart) => {
    store.commit(QuoteMutations.UpdatePart, part);
  },
  removePart: (store: ActionContext<QuoteState, RootState>, part: QuotePart) => {
    store.commit(QuoteMutations.RemovePart, part);
  },
  setPartIsCollapsed: (store: ActionContext<QuoteState, RootState>, part: QuotePart) => {
    store.commit(QuoteMutations.SetPartIsCollapsed, part);
  },
  removeParts: (store: ActionContext<QuoteState, RootState>, parts: number[]) => {
    store.commit(QuoteMutations.RemoveParts, parts);
  },
};

export default actions;
