
    import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
    import Button from '@/components/Button.vue';
    import { Mode } from '@/store/enums';
    import { AccessLevel, User, UserRole } from '@/models/user';
    import { PersistentGetters } from '@/store/persistent/enums';
    import { MaterialFormat } from '../../models/materialFormat';
    import { Material } from '../../models/material';
    import { Tenant } from '../../models/tenant';
    import { materialsService } from '../../services/materials.service';
    import MaintainMaterialFormat from '../../components/materials/MaintainMaterialFormat.vue';
    import { materialFormatsService } from '../../services/materialFormats.service';

    @Component({
        components: {
            'sc-button': Button,
            'sc-maintain-material-format': MaintainMaterialFormat,
        },
    })
    export default class MaterialFormatsWithParent extends Vue {

        @Prop({ required: true, default: Material.GetEmpty() })
        public material!: Material;

        @Prop({required: true, default: Tenant.GetEmpty()})
        public tenant!: Tenant;

        public isPaginationSimple = false;
        public paginationPosition = 'bottom';
        public defaultSortDirection = 'asc';
        public sortIcon = 'arrow-up';
        public sortIconSize = 'is-small';
        public currentPage = 1;
        public perPage = 5;
        public isPaginated = true;

        public formats: MaterialFormat[] = [];

        @Ref('maintainFormat')
        public maintainFormat!: MaintainMaterialFormat;

        public async created() {
            await this.getMaterialFormats();
        }

        public async modalClose() {
            await this.getMaterialFormats();
        }

        public async startCreate() {
            this.maintainFormat.openModalExternal(
                Mode.Create,
                MaterialFormat.GetEmpty(),
            );
        }

        public async startEdit(format: MaterialFormat) {
            this.$spinner.showSpinner();
            const editableFormat: MaterialFormat = await materialFormatsService.getById(format.id);
            this.$spinner.removeSpinner();

            this.maintainFormat.openModalExternal(
                this.isReadOnly ? Mode.View : Mode.Edit,
                editableFormat,
            );
        }

        public confirmDelete(id: number, thickness: number): void {
            this.$buefy.dialog.confirm({
                title: this.$i18n.t('dialog.confirm_title').toString(),
                message: this.$i18n.t('format.dialog.delete').toString() + ` <b>${thickness}</b>?`,
                confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
                cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteFormat(id),
            });
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isReadOnly(): boolean {
            return this.currentUser.role === UserRole.Viewer;
        }

        private async getMaterialFormats() {
            this.$spinner.showSpinner();
            if (this.material && this.material.id > 0) {
                this.formats = await materialsService.getMaterialFormats(this.material.id);
            }
            this.$spinner.removeSpinner();
        }

        private deleteFormat(formatId: number) {
            this.$spinner.showSpinner();
            materialFormatsService
                .delete(formatId)
                .then(() => {
                    this.formats = this.formats.filter((x) => x.id !== formatId);
                })
                .finally(() => {
                    this.$spinner.removeSpinner();
                });
        }

        private get thicknessUnit() {
            if (this.tenant.measurement && this.tenant.measurement === '2') {
                return this.$i18n.t(`units.distance.inches.abbreviation`);
            } else {
                return this.$i18n.t(`units.distance.millimeters.abbreviation`);
            }
        }

        private get currency() {
            return this.tenant.currencyAbbreviation;
        }

        private get materialCostUnit() {
            let weightUnit;
            if (this.tenant.measurement && this.tenant.measurement === '2') {
                weightUnit = this.$i18n.t(`units.weight.pound.abbreviation`);
            } else {
                weightUnit = this.$i18n.t(`units.weight.kilo.abbreviation`);
            }
            return `${this.currency}/${weightUnit}`;
        }

    }
