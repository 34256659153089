
import { Subsidiary } from '@/models/subsidiary';
import { Routes } from '@/router';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Component, Vue } from 'vue-property-decorator';
import UsersWithParent from '@/views/users/UsersWithParent.vue';
import SubsidiaryProperties from './SubsidiaryProperties.vue';

@Component({
  components: {
    'sc-subsidiary-properties': SubsidiaryProperties,
    'sc-subsidiary-users': UsersWithParent,
  },
})
export default class SubsidiaryDetail extends Vue {
  public id: number = 0;
  public subsidiary: Subsidiary = Subsidiary.GetEmpty();

  public async created(): Promise<void> {
    if (!this.$route.params || !this.$route.params.id) {
      this.$router.push({ name: Routes.Subsidiaries });
    } else {
      this.id = +this.$route.params.id;
      await this.getSubsidiaryById(this.id);
    }
  }

  public async getSubsidiaryById(id: number) {
    this.$spinner.showSpinner();
    this.subsidiary = await subsidiariesService.getById(id);
    this.$spinner.removeSpinner();
  }

  public async update() {
    await this.getSubsidiaryById(this.subsidiary.id);
  }
}
