
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: '' })
  public icon!: string;

  @Prop({ default: '' })
  public text!: string;

  @Prop({ default: '' })
  public size!: string;

  @Prop({ default: false })
  public expanded!: boolean;

}
