import { User } from '@/models/user';
import { GetterTree } from 'vuex';
import RootState from '../rootState';
import PersistentState from './persistentState';

const getters: GetterTree<PersistentState, RootState> = {
    getLocale: (rootState: PersistentState) => rootState.locale,
    getStream: (rootState: PersistentState) => rootState.stream,
    getTimezones: (rootState: PersistentState) => rootState.timezones,
    getCurrencyCodes: (rootState: PersistentState) => rootState.currencyCodes,
    getMeasurements: (rootState: PersistentState) => rootState.measurements,
    getServices: (rootState: PersistentState) => rootState.services,
    getDeviceBaseModels: (rootState: PersistentState) => rootState.deviceBaseModels,
    currentUser: (rootState: PersistentState) => rootState.currentUser,
    isAuthenticated: (rootState: PersistentState) => rootState.currentUser !== {} as User,
    getMaterialGroups: (rootState: PersistentState) => rootState.materialGroups,
    getTechnologies: (rootState: PersistentState) => rootState.technologies,
    getRejectReasons: (rootState: PersistentState) => rootState.rejectReasons,
    getAddresses: (rootState: PersistentState) => rootState.addresses,
};

export default getters;
