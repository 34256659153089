import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Subsidiary } from '@/models/subsidiary';
import { User } from '@/models/user';

class SubsidiariesService {
  public get(): Promise<Subsidiary[]> {
    return axios.get<Subsidiary[]>(`${BaseUrl}/subsidiaries`).then((response: any) => {
      return response.data;
    });
  }

  public getById(id: number): Promise<Subsidiary> {
    return axios.get<Subsidiary>(`${BaseUrl}/subsidiaries/${id}`).then((response: any) => {
      return response.data;
    });
  }

  public delete(id: number): Promise<Subsidiary[]> {
    return axios.delete(`${BaseUrl}/subsidiaries/${id}`).then((response: any) => {
      return response.data;
    });
  }

  public update(subsidiary: Subsidiary) {
    return axios.put(`${BaseUrl}/subsidiaries/`, subsidiary).then((response: any) => {
      return response.data;
    });
  }

  public create(subsidiary: Subsidiary) {
    return axios.post(`${BaseUrl}/subsidiaries/`, subsidiary).then((response: any) => {
      return response.data;
    });
  }

  public getUsers(id: number): Promise<User[]> {
    return axios.get(`${BaseUrl}/subsidiaries/${id}/users`).then((response: any) => {
      return response.data;
    });
  }
}

export const subsidiariesService = new SubsidiariesService();
