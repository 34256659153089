
import { Component, Vue, Prop } from 'vue-property-decorator';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Subsidiary } from '@/models/subsidiary';
import { hasPermission } from '@/utils/permissionUtils';
import { RootGetters } from '@/store/enums';
import { AccessLevel } from '@/models/user';
@Component
export default class SubsidiariesSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: Subsidiary[] = [];

  public mounted(): void {
    this.getItems();
  }
  public async getItems() {
    this.$spinner.showSpinner();
    // if (hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SubsidiaryViewer)) {
    this.items = await subsidiariesService.get();
    // }
    this.$spinner.removeSpinner();
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
