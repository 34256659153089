import { QuoteStatusEnum } from './enums/QuoteStatusEnum';
import { QuotePart } from './QuotePart';
import { Address } from './address';

export class Quote {
  public static GetEmpty(): Quote {
    return new Quote(0,
      '',
      0,
      0,
      QuoteStatusEnum.None,
      new Date(),
      0,
      [],
      0,
      '',
      null,
      null,
      null,
      0,
      0,
      false,
      null,
      null);
  }
  constructor(
    public id: number,
    public reference: string,
    public userId: number,
    public endCustomerId: number,
    public status: QuoteStatusEnum,
    public CreatedAt: Date,
    public shippingPrice: number,
    public parts: QuotePart[],
    public rejectReasonId: number | null,
    public currencySymbol: string,
    public measurementUnit: string | null,
    public shippingAddress: Address | null,
    public addressId: number | null,
    public processingPrice: number,
    public totalPrice: number,
    public hasReport: boolean,
    public saleOrderId: number | null,
    // public errorQuote: boolean,
    public isIntegratedWithERPSystem: boolean | null,
  ) { }
}
