
import { Tenant } from '@/models/tenant';
import { EndCustomer } from '@/models/endCustomer';
import { Routes } from '@/router';
import { Quote } from '@/models/quote';
import { quotesService } from '@/services/quotes.service';
import { saleOrdersService } from '@/services/saleOrders.service';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { QuoteSummary } from '@/models/quoteSummary';
import { SaleOrder } from '@/models/saleOrder';
import QuoteSummaryCard from '@/components/quotes/QuoteSummaryCard.vue';
import SaleOrderSummaryCard from '@/components/saleOrders/SaleOrderSummaryCard.vue';
import Button from '@/components/Button.vue';
import { QuoteStatusEnum } from '@/models/enums/QuoteStatusEnum';
import { SaleOrderStatusEnum } from '@/models/enums/SaleOrderStatusEnum';
import { QuoteFilter } from '@/models/QuoteFilter';
import { SaleOrderFilter } from '@/models/saleOrderFilter';
import { PersistentGetters } from '@/store/persistent/enums';
import { User } from '@/models/user';
import RejectQuote from '@/components/quote/RejectQuote.vue';
import Guidelines from '@/components/quotes/Guidelines.vue';

@Component({
  components: {
    'sc-quote-summary-card': QuoteSummaryCard,
    'sc-button': Button,
    'sc-reject-quote': RejectQuote,
    'sc-quotes-guidelines': Guidelines,
    'sc-sale-order-summary-card': SaleOrderSummaryCard,
  },
})
export default class DashboardWithParent extends Vue {
  @Ref('rejectQuote')
  public rejectQuoteComponent!: RejectQuote;
  public tenant: Tenant = Tenant.GetEmpty();
  public endCustomer: EndCustomer = EndCustomer.GetEmpty();
  public selectedTab: number = 0;
  public quoteSummaries: QuoteSummary[] = [];
  public draftQuoteSummaries: QuoteSummary[] = [];
  public orderSummaries: SaleOrder[] = [];

  private async created(): Promise<void> {
    await this.getDraftQuotes();
    // await this.getSaleOrders();
  }

  private async getDraftQuotes() {
    this.$spinner.showSpinner();
    try {
      const filter: QuoteFilter = new QuoteFilter(QuoteStatusEnum.Draft, this.user.dashboardDraftQuoteQty);
      this.draftQuoteSummaries = await quotesService.get(filter);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private async RefreshAfterRejected() {
    await this.getDraftQuotes();
  }

  private async getSaleOrders() {
    this.$spinner.showSpinner();
    try {
      const filter: SaleOrderFilter =
        new SaleOrderFilter(SaleOrderStatusEnum.InProgress, this.user.dashboardAcceptedQuoteQty);
      this.orderSummaries = await saleOrdersService.get(filter);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private get user(): User {
    return this.$store.getters[PersistentGetters.CurrentUser] as User;
  }

  private startCreate() {
    quotesService.create().then((quote: Quote) => {
      this.$router.push({ name: Routes.QuoteParent, params: { id: quote.id.toString() } });
    });
    // this.$router.push(Routes.QuoteParent);
  }

  private discardQuote(quoteId: number) {
    this.rejectQuoteComponent.openModal(quoteId);
  }
}
