import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { reportsService } from './reports.service';
import { QuoteSummary } from '../models/quoteSummary';
import { QuoteFilter } from '../models/QuoteFilter';
import { Quote } from '@/models/quote';
import { QuotePart } from '@/models/QuotePart';

class QuotesService {
  public create(): Promise<Quote> {
    return axios
      .post(`${BaseUrl}/quotes/`, {})
      .then((response: any) => {
        return response.data;
    });
  }

  public save(quote: Quote, abortSignal?: AbortSignal) {
    return axios.put(`${BaseUrl}/quotes/save`, quote, { signal: abortSignal }).then((response: any) => {
        return response.data;
    });
  }

  public update(quote: Quote, abortSignal?: AbortSignal) {
    return axios.put(`${BaseUrl}/quotes/`, quote, { signal: abortSignal }).then((response: any) => {
      return response.data;
    });
  }

  public get(filter: QuoteFilter): Promise<QuoteSummary[]> {
    return axios
      .get<Quote[]>(`${BaseUrl}/quotes`, { params: filter })
      .then((response: any) => {
        responseHandler.handleDates(response.data);
        return response.data;
      });
  }

  public getById(id: number, abortSignal?: AbortSignal): Promise<Quote> {
    return axios.get<Quote>(`${BaseUrl}/quotes/${id}`, { signal: abortSignal }).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
    }

  public async showReport(id: number) {
    const url = `${BaseUrl}/quotes/${id}/report`;
    return await reportsService.showReport(url);
  }

  public addPart(quoteId: number, file: File) {
    const formData = new FormData();
    formData.append('files', file);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${BaseUrl}/quotes/${quoteId}/part`, formData, config);
  }

  public addParts(quoteId: number, files: File[]) {
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files', file);
    });

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    return axios.post(`${BaseUrl}/quotes/${quoteId}/part`, formData, config);
  }

  public updatePart(part: QuotePart, abortSignal?: AbortSignal) {
    const data = {
      id: part.id,
      materialGroupId: part.materialGroupId,
      materialId: part.materialId,
      thickness: part.thickness,
      units: part.units,
      technologies: part.technologies,
      measurementUnit: part.measurementUnit,
    };

    return axios.put(`${BaseUrl}/quotes/${part.quoteId}/part`, data, { signal: abortSignal });
  }

  public deletePart(partId: number) {
    return axios.delete(`${BaseUrl}/quotes/part/${partId}`);
  }

  public updateStatus(quoteId: number, status: number,  rejectReasonId: any) {
    const data = {
      quoteId,
      status,
      rejectReasonId,
    };

    return axios.put(`${BaseUrl}/quotes/update-status`, data).then((response: any) => {
      return response.data;
    });
  }

  public deleteParts(parts: number[]) {
    return axios.post(`${BaseUrl}/quotes/deleteparts`, parts);
  }
}

export const quotesService = new QuotesService();
