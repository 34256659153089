
import { Component, Vue } from 'vue-property-decorator';
import { ReportsMenu } from '@/models/menu';
import { RootGetters } from '@/store/enums';
import { Routes } from '@/router';
import i18n from '@/i18n';
import { PersistentActions, PersistentGetters } from '@/store/persistent/enums';
import { User, UserRole } from '@/models/user';
import { hasEndCustomerScope, hasSubsidiaryScope, hasTenantScope } from '@/utils/permissionUtils';
import { usersService } from '@/services/usersService';
import signalRService from '@/services/signalR.service';

@Component
export default class Header extends Vue {
  public isLoggedIn = false;
  public routes = Routes;
  public locale = i18n.locale;
  public locales = i18n.availableLocales;

  public updateShowMenu() {
    this.$emit('menuchange');
  }

  public get menus() {
    return this.$store.getters[RootGetters.GetReportsMenu].reduce((rv: any, x: ReportsMenu) => {
      (rv[x.categoryMenu.name] = rv[x.categoryMenu.name] || []).push(x);
      return rv;
    }, {});
  }

  public get dashboardPath(): string {
    // let endCustomerId = '';
    // if(this.user.) TODO add customer Id if user belongs to endCustomer
    return '/dashboard';
  }

  public get showBurger(): boolean {
    return this.menus && Object.keys(this.menus).length > 0;
  }

  public async created() {
    signalRService.connection.on('messageQuote', (message: any) => {
      setTimeout(() => {
        const text: string = message;
        const messagepart1: string = text.substring(0, text.lastIndexOf(' '));
        const textlocale: string = this.$i18n.t(messagepart1).toString();
        const quote: string = text.substring(text.lastIndexOf(' '));
        this.$buefy.toast.open({ message: `${textlocale} ${quote}`, type: 'is-success' });
        if (this.$route.name === 'dashboard') {
          setTimeout(() => {
            location.reload();
          }, 2000);
        }
      }, 3000);
    });
    this.isLoggedIn = await this.$auth.isLoggedIn();
  }

  public async logout() {
    this.$store.dispatch(PersistentActions.SetCurrentUser, {});
    await this.$auth.logout();
  }

  public setLocale(locale: string) {
    i18n.locale = this.locale = locale;
    this.$store.dispatch(PersistentActions.SetLocale, locale);
  }

  public get user(): User {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isSuperAdmin(): boolean {
    return this.user.role === UserRole.Superadmin;
  }

  public get showSubidiaries(): boolean {
    return this.isSuperAdmin || hasSubsidiaryScope(this.user);
  }

  public get isTenantOnly(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role === UserRole.Viewer;
  }

  public get showSettings(): boolean {
    return (
      this.$store.getters[PersistentGetters.CurrentUser].role !== UserRole.Viewer &&
      (this.isSuperAdmin || hasSubsidiaryScope(this.user) || hasTenantScope(this.user))
    );
  }

  public get showTenants(): boolean {
    return this.isSuperAdmin || hasSubsidiaryScope(this.user) || hasTenantScope(this.user);
  }

  public get showEndCustomers(): boolean {
    return this.isSuperAdmin || hasTenantScope(this.user) || hasEndCustomerScope(this.user);
  }

  public get showUsers(): boolean {
    return this.isSuperAdmin;
  }

  public get showDevices(): boolean {
    return this.isSuperAdmin;
  }

  public get showKpiGroups(): boolean {
    return this.isSuperAdmin || hasSubsidiaryScope(this.user);
  }

  public get showDashboard(): boolean {
    return hasEndCustomerScope(this.user);
  }

  public downloadTermsAndConditions() {
    this.$spinner.showSpinner();
    usersService.terms(this.locale).finally(() => this.$spinner.removeSpinner());
  }

  public resetTermsAcceptance() {
    this.$spinner.showSpinner();
    usersService.resetTermsAcceptance().then(() => {
      this.$buefy.toast.open({
                duration: 5000,
                message: `${this.$i18n.t('terms.reset_success')}`,
                type: 'is-success',
        });
    }).catch(() => {
      this.$buefy.toast.open({
        duration: 5000,
        message: `${this.$i18n.t('terms.reset_failure')}`,
        type: 'is-danger',
      });
    }).finally(() => {
      this.$spinner.removeSpinner();
    });
  }
}
