
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { TimeZone } from '@/models/timeZone';
    import { PersistentGetters } from '@/store/persistent/enums';
    @Component
    export default class TimezonesSelect extends Vue {
        @Prop({ default: '' })
        public value!: string;

        @Prop({ default: false })
        public required!: boolean;

        @Prop({ default: false })
        public disabled!: boolean;

        public items: TimeZone[] = [];

        public mounted(): void {
            this.items = this.getItems();
        }
        public getItems() {
            const timezones = this.$store.getters[PersistentGetters.GetTimezones] as TimeZone[];
            timezones.forEach((timezone) => {
                timezone.name = this.$i18n.t('timezone.' + timezone.id).toString();
            });
            timezones.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return timezones;
        }

        public handleSelect(value: string) {
            this.$emit('input', value);
            this.$emit('change', value);
        }
    }
