import { GetterTree } from 'vuex';
import RootState from '@/store/rootState';

const getters: GetterTree<RootState, RootState> = {
    getReportsMenu: (rootState: RootState) => rootState.reportsMenu,
    spinners: (rootState: RootState) => rootState.spinners,
    termsAcceptedSession: (rootState: RootState) => rootState.termsAcceptedSession,
    userInfoLoaded: (rootState: RootState) => rootState.userInfoLoaded,
};

export default getters;
