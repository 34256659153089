import { Subsidiary } from '../models/subsidiary';
import { Tenant } from '../models/tenant';
import { EndCustomer } from '../models/endCustomer';

export class User {
  public static GetEmpty(): User {
    return new User(0, '', 0, null, null, null, '', null, 0, '', '', null, null, null, null, false, null, null,
      null, null);
  }
  public static GetUserRolesArray(): any {
    return Object.keys(UserRole).map((key) => UserRole[(key as unknown) as number]);
  }
  public static GetCopy(original: User): User {
    return new User(
      original.id,
      original.name,
      original.role,
      original.tenantId,
      original.subsidiaryId,
      original.endCustomerId,
      original.email,
      original.parentType,
      original.type,
      original.password,
      original.surname,
      original.language,
      original.dashboardAcceptedQuoteQty,
      original.dashboardDraftQuoteQty,
      original.gridElementQty,
      original.isAcceptedTermsOfAgreement,
      original.timestampAcceptedTermsOfAgreement,
      original.tenant,
      original.subsidiary,
      original.endCustomer,
    );
  }

  public tenantName!: string;
  public subsidiaryName!: string;
  public endCustomerName!: string;
  public roleName!: string;
  constructor(
    public id: number,
    public name: string,
    public role: UserRole,
    public tenantId: number | null,
    public subsidiaryId: number | null,
    public endCustomerId: number | null,
    public email: string,
    public parentType: number | null,
    public type: UserType,
    public password: string,
    public surname: string,
    public language: UserLanguage | null,
    public dashboardAcceptedQuoteQty: number | null,
    public dashboardDraftQuoteQty: number | null,
    public gridElementQty: number | null,
    public isAcceptedTermsOfAgreement: boolean,
    public timestampAcceptedTermsOfAgreement: Date | null,
    public tenant: Tenant | null,
    public subsidiary: Subsidiary | null,
    public endCustomer: EndCustomer | null,
  ) {
    this.tenantName = 'orig';
    this.subsidiaryName = 'orig';
    this.endCustomerName = 'orig';
  }
}

export enum UserRole {
  None = 0,
  Superadmin = 1,
  Admin = 2,
  Viewer = 3,
  Global = 4,
}

export enum AccessLevel {
  None = 0,
  SuperAdmin = 1,
  SubsidiaryAdmin = 2,
  SubsidiaryViewer = 3,
  TenantAdmin = 4,
  TenantViewer = 5,
  EndCustomerAdmin = 6,
  EndCustomerGlobal = 7,
  EndCustomerUser = 8,
}

export enum UserType {
  None = 0,
  Local = 1,
  AAD = 2,
}

export enum UserLanguage {
  None = 0,
  En = 1,
  Es = 2,
}
