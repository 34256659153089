
import { Watch } from 'vue-property-decorator';
import { Address } from '@/models/address';
import { Component, Prop, Vue } from 'vue-property-decorator';
import QuoteElement from './QuoteElement.vue';

@Component({
  components: {
    'sc-quote-element': QuoteElement,
  },
})
export default class QuoteShippingSelect extends Vue {
  @Prop({ required: false, default: () => [] })
  public availableAddresses!: Address[];

  @Prop({ required: false })
  public shippingAddress!: Address;

  @Prop({ required: false, default: false })
  public readOnly!: boolean;

  @Prop({ required: false, default: null })
  public selectedAddressId!: number;

  @Watch('selectedAddressId')
  public selectedAddressIdChanged() {
    if (this.selectedAddressId > 0) {
      this.$emit('selectedAddressIdChanged', this.selectedAddressId);
    }
  }
}
