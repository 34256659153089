
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { tenantsService } from '@/services/tenants.service';
import { Routes } from '@/router';
import CreateTenant from '../../components/tenants/CreateTenant.vue';
import Button from '@/components/Button.vue';
import { AccessLevel } from '@/models/user';
import { hasPermission } from '@/utils/permissionUtils';
import { PersistentGetters } from '@/store/persistent/enums';
import CreateTenantModal from '@/components/tenants/CreateTenantModal.vue';

@Component({
  components: {
    'sc-create-tenant': CreateTenantModal,
    'sc-button': Button,
  },
})
export default class Tenants extends Vue {
  public tenants: Tenant[] = [];
  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;
  public routes = Routes;

  public async created() {
    await this.getTenants();
  }

  public confirmDelete(id: number): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('tenant.dialog.delete').toString() + ` <b>${id}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteTenant(id),
    });
  }

  public async editTenant(tenant: Tenant) {
    this.$router.push({ name: Routes.TenantDetail, params: { id: tenant.id.toString() } });
  }

  public async modalClose() {
    await this.getTenants();
  }

  private async getTenants() {
    this.$spinner.showSpinner();
    this.tenants = await tenantsService.get().finally(() => this.$spinner.removeSpinner());
  }

  private deleteTenant(id: number): void {
    this.$spinner.showSpinner();
    tenantsService
      .delete(id)
      .then(() => {
        this.tenants = this.tenants.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  public get canEditTenants(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SubsidiaryAdmin);
  }

  public get isSuperAdmin(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SuperAdmin);
  }
}
