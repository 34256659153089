
import { SaleOrderStatusEnum } from '@/models/enums/SaleOrderStatusEnum';
  import { SaleOrder } from '@/models/saleOrder';
  import { Tenant } from '@/models/tenant';
  import { User } from '@/models/user';
  import { Routes } from '@/router';
  import { PersistentGetters } from '@/store/persistent/enums';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import Button from '../Button.vue';

  @Component({
    components: {
      'sc-button': Button,
    },
  })
  export default class SaleOrderSummaryCard extends Vue {
    @Prop({})
    private item!: SaleOrder;

    private get user(): User {
      return this.$store.getters[PersistentGetters.CurrentUser];
    }

    private details() {
      this.$router.push({ name: Routes.SaleOrder, params: { id: this.item.quoteId.toString() } });
    }
  }
