import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { responseHandler } from './response.handler';
import { Shipping } from '../models/shipping';

class ShippingsService {
  public get(): Promise<Shipping[]> {
    return axios.get<Shipping[]>(`${BaseUrl}/shippings`).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public getById(id: number): Promise<Shipping> {
    return axios.get<Shipping>(`${BaseUrl}/shippings/${id}`).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public delete(id: number): Promise<Shipping[]> {
    return axios.delete(`${BaseUrl}/shippings/${id}`).then((response: any) => {
      return response.data;
    });
  }

  public update(tenant: Shipping) {
    return axios.put(`${BaseUrl}/shippings/`, tenant).then((response: any) => {
      return response.data;
    });
  }

  public create(tenant: Shipping) {
    return axios.post(`${BaseUrl}/shippings/`, tenant).then((response: any) => {
      return response.data;
    });
  }
}

export const shippingsService = new ShippingsService();
