import { AddressTypeEnum } from './enums/AddressTypeEnum';

export class Address {
    public static GetEmpty(): Address {
        return new Address(
            0,
            0,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            0,
            null,
            null,
            null,
        );
    }

    public static GetCopy(original: Address): Address {
        return new Address(
            original.id,
            original.endCustomerId,
            original.name,
            original.type,
            original.line1,
            original.line2,
            original.zipCode,
            original.city,
            original.country,
            original.shippingId,
            original.leadTime,
            original.shippingFixedCost,
            original.shippingCostPerWeight,
        );
    }

    constructor(
        public id: number,
        public endCustomerId: number,
        public name: string | null,
        public type: AddressTypeEnum | null,
        public line1: string | null,
        public line2: string | null,
        public zipCode: string | null,
        public city: string | null,
        public country: string | null,
        public shippingId: number | null,
        public leadTime: string | null,
        public shippingFixedCost: number | null,
        public shippingCostPerWeight: number | null,
    ) { }
}
