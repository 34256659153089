import { MutationTree } from 'vuex';
import QuoteState from './quoteState';
import { Material, QuotePart, Technology } from '@/models/QuotePart';
import { Quote } from '@/models/quote';

const mutations: MutationTree<QuoteState> = {
  setQuote(state: QuoteState, quote: Quote) {
    state.quote = quote;
  },
  addPart(state: QuoteState, parts: QuotePart[]) {
    if (parts && parts.length > 0) {
      parts.forEach((part) => {
        part.isCollapsed = false;
        part.originalArea = part.area;
        part.originalLength = part.length;
        part.originalWidth = part.width;
        // part.material = {} as Material;
        part.possibleMaterials = [];
        part.possibleMaterialFormats = [];
        part.defaultMeasurement = 0;
        part.isMaterialError = false;
        part.isThicknessError = false;
        part.isSelected = false;
        part.originalTechnologies = [...part.technologies];

        state.quote.parts.push(part);
      });
    }
  },
  resetParts(state: QuoteState) {
    state.quote.parts = [];
  },
  updatePart(state: QuoteState, part: QuotePart) {
    const newQuote = { ...state.quote };

    const index = newQuote.parts.findIndex((x) => x.id === part.id);
    if (index >= 0) {
      // newQuote.parts[index] = part;
      newQuote.parts.splice(index, 1, part);
    }
    state.quote = newQuote;
  },
  removePart(state: QuoteState, part: QuotePart) {
    const index = state.quote.parts.findIndex((x) => x.id === part.id);
    if (index >= 0) {
      state.quote.parts.splice(index, 1);
    }
  },
  setPartIsCollapsed(state: QuoteState, part: QuotePart) {
    const partToToggle = state.quote.parts.find((p) => p.id === part.id);
    if (partToToggle) {
      partToToggle.isCollapsed = part.isCollapsed;
    }
  },
  removeParts(state: QuoteState, parts: number[]) {
    if (state.quote && state.quote.parts && state.quote.parts.length > 0) {
      const remainingParts = state.quote.parts.filter((part) => !parts.includes(part.id));
      state.quote.parts = remainingParts;
    }
  },
};

export default mutations;
