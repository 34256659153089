
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
import { UserRole } from '@/models/user';
import { Tenant } from '@/models/tenant';
import { PersistentGetters } from '@/store/persistent/enums';
import { MaterialGroup } from '../../models/materialGroup';
import { Mode } from '@/store/enums';
import MaterialsWithParent from '@/views/materials/MaterialsWithParent.vue';
import { materialGroupsService } from '../../services/materialGroups.service';
import { tenantsService } from '../../services/tenants.service';
import MaintainMaterialGroup from '@/components/materials/MaintainMaterialGroup.vue';

@Component({
    components: {
        'sc-button': Button,
        'sc-materials': MaterialsWithParent,
        'sc-maintain-material-group': MaintainMaterialGroup,
    },
})
export default class MaterialGroupsWithParent extends Vue {

    @Prop({ required: false, default: Tenant.GetEmpty() })
    public tenant!: Tenant;

    public isPaginationSimple = false;
    public paginationPosition = 'bottom';
    public defaultSortDirection = 'asc';
    public sortIcon = 'arrow-up';
    public sortIconSize = 'is-small';
    public currentPage = 1;
    public perPage = 10;
    public isPaginated = true;

    @Ref('maintainMaterialGroup')
    public maintainMaterialGroup!: MaintainMaterialGroup;

    public materialGroups: MaterialGroup[] = [];

    public async created() {
        await this.getMaterialGroups();
    }

    public async startCreate() {
        this.maintainMaterialGroup.openModalExternal(
            Mode.Create,
            MaterialGroup.GetEmpty(),
         );
    }

    public async startEdit(group: MaterialGroup) {
        this.$spinner.showSpinner();
        const editableGroup: MaterialGroup = await materialGroupsService.getById(group.id);
        this.$spinner.removeSpinner();

        this.maintainMaterialGroup.openModalExternal(
        this.isReadOnly ? Mode.View : Mode.Edit,
        editableGroup,
        );
    }

    public confirmDelete(id: number, name: string): void {
        this.$buefy.dialog.confirm({
            title: this.$i18n.t('dialog.confirm_title').toString(),
            message: this.$i18n.t('materialGroup.dialog.delete').toString() + ` <b>${name}</b>?`,
            confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
            cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deleteMaterialGroup(id),
        });
    }

    public async modalClose() {
        await this.getMaterialGroups();
    }

    public get currentUser() {
        return this.$store.getters[PersistentGetters.CurrentUser];
    }

    public get isReadOnly(): boolean {
        return this.currentUser.role === UserRole.Viewer;
    }

    private get currencySymbol() {
        return this.tenant.currencyAbbreviation;
    }

    private get weightUnit() {
        if (this.tenant.measurement === '1') {
            return 'kg';
        }
        if (this.tenant.measurement === '2') {
            return 'lb';
        }
        return '';
    }

    private async getMaterialGroups() {
        this.$spinner.showSpinner();
        if (this.tenant && this.tenant.id > 0) {
            this.materialGroups = await tenantsService.getMaterialGroups(this.tenant.id);
        }
        this.$spinner.removeSpinner();
    }

    private deleteMaterialGroup(id: number): void {
         this.$spinner.showSpinner();
         materialGroupsService
            .delete(id)
            .then(() => {
                this.materialGroups = this.materialGroups.filter((x) => x.id !== id);
            })
            .finally(() => {
                this.$spinner.removeSpinner();
            });
    }
}
