import { ActionTree, ActionContext } from 'vuex';
import { RootMutations } from '@/store/enums';
import RootState from '@/store/rootState';
import { ReportsMenu } from '@/models/menu';

const actions: ActionTree<RootState, any> = {
  setReportsMenu: (store: ActionContext<RootState, RootState>, value: ReportsMenu[]) => {
    store.commit(RootMutations.SetReportsMenu, value);
  },
  showSpinner: (store: ActionContext<RootState, any>) => {
    store.commit(RootMutations.ShowSpinner);
  },
  removeSpinner: (store: ActionContext<RootState, any>) => {
    store.commit(RootMutations.RemoveSpinner);
  },
  setTermsAcceptedSession: (store: ActionContext<RootState, any>, termsAcceptedSessionValue: boolean) => {
    store.commit(RootMutations.SetTermsAcceptedSession, termsAcceptedSessionValue);
  },
  setUserInfoLoaded: (store: ActionContext<RootState, any>, userInfoLoadedValue: boolean) => {
      store.commit(RootMutations.SetUserInfoLoaded, userInfoLoadedValue);
  },
};

export default actions;
