import { required, email } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import i18n from '@/i18n';
import { ConfigurationSpa } from '@/models/configurationSpa';


extend('required', {
    ...required,
    message: () => i18n.t('form.input_validation').toString(),
});

extend('email', {
    ...email,
    message: () => i18n.t('form.input_email_validation').toString(),
});

extend('positive', (value) => {
    if (value >= 0) {
        return true;
    }

    return i18n.t('form.input_positive_validation').toString();
});

extend('strictPositive', (value) => {
    if (value > 0) {
        return true;
    }

    return i18n.t('form.input_strict_positive_validation').toString();
});

extend('leadTimeFormat', (value) => {

    const spaConfig: ConfigurationSpa = JSON.parse(sessionStorage.getItem('spa.config') || '');

    const regex = new RegExp(spaConfig.leadTimeFormatPattern);
    const result = regex.test(value);

    if (result) { return true; }

    return i18n.t('form.input_lead_time_format').toString();

});

