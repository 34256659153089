
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { AccessLevel, User, UserRole } from '@/models/user';
import { Tenant } from '@/models/tenant';
import { usersService } from '@/services/usersService';
import { Routes } from '@/router';
import { Subsidiary } from '@/models/subsidiary';
import { tenantsService } from '@/services/tenants.service';
import Button from '@/components/Button.vue';
import { PersistentGetters } from '@/store/persistent/enums';
import MaintainUser2 from '@/components/users/MaintainUser2.vue';
import { Mode } from '@/store/enums';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { EndCustomer } from '@/models/endCustomer';
import { endCustomersService } from '@/services/endCustomers.service';

@Component({
  components: {
    'sc-maintain-user': MaintainUser2,
    'sc-button': Button,
  },
})
export default class UsersWithParent extends Vue {
  @Prop({
    required: false,
    default() {
      return Tenant.GetEmpty();
    },
  })
  public tenant!: Tenant;
  @Prop({
    required: false,
    default() {
      return Subsidiary.GetEmpty();
    },
  })
  public subsidiary!: Subsidiary;
  @Prop({
    required: false,
    default() {
      return EndCustomer.GetEmpty();
    },
  })
  public endCustomer!: EndCustomer;

  @Ref('maintainUser')
  public maintainUser!: MaintainUser2;

  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;
  public routes = Routes;

  public users: User[] = [];
  public subsidiaries: Subsidiary[] = [];
  public tenants: Tenant[] = [];
  public endCustomers: EndCustomer[] = [];
  public roleTranslations: any[] = [];

  public confirmDelete(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('user.dialog.delete').toString() + ` <b>${name}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteUser(id),
    });
  }

  public async startCreate() {
    this.maintainUser.openModalExternal(
      Mode.Create,
      User.GetEmpty(),
      this.subsidiary?.id,
      this.tenant?.id,
      this.endCustomer?.id,
    );
  }

  public async startEdit(user: User) {
    this.$spinner.showSpinner();
    const editableUser: User = await usersService.getById(user.id);
    this.$spinner.removeSpinner();

    this.maintainUser.openModalExternal(
      this.isReadOnly ? Mode.View : Mode.Edit,
      editableUser,
      this.subsidiary?.id,
      this.tenant?.id,
      this.endCustomer?.id,
    );
  }
  public subsidiaryName(subsidiaryId: number) {
    return this.subsidiaries.find((s) => s.id === subsidiaryId)?.name ?? '-';
  }

  public tenantName(tenantId: number) {
    return this.tenants.find((s) => s.id === tenantId)?.name ?? '-';
  }

  public endCustomerName(endCustomerId: number) {
    return this.endCustomers.find((s) => s.id === endCustomerId)?.name ?? '-';
  }

  public async modalClose() {
    await this.getUsers();
  }

  public async created() {
    await this.getUsers();
    if (this.showParentColumns) {
      await this.getSubsidiaries();
      await this.getTenants();
      await this.getEndCustomers();
    }
  }

  private async getUsers() {
    // TODO end customers
    this.$spinner.showSpinner();
    if (this.subsidiary && this.subsidiary.id > 0) {
      this.users = await subsidiariesService.getUsers(this.subsidiary.id);
    } else if (this.tenant && this.tenant.id > 0) {
      this.users = await tenantsService.getUsers(this.tenant.id);
    } else if (this.endCustomer && this.endCustomer.id > 0) {
      this.users = await endCustomersService.getUsers(this.endCustomer.id);
    } else {
      this.users = await usersService.get();
    }
    this.$spinner.removeSpinner();
  }

  private async getSubsidiaries() {
    this.$spinner.showSpinner();
    this.subsidiaries = await subsidiariesService.get().finally(() => this.$spinner.removeSpinner());
  }

  private async getTenants() {
    this.$spinner.showSpinner();
    this.tenants = await tenantsService.get().finally(() => this.$spinner.removeSpinner());
  }

  private async getEndCustomers() {
    this.$spinner.showSpinner();
    this.endCustomers = await endCustomersService.get().finally(() => this.$spinner.removeSpinner());
  }

  private deleteUser(id: number): void {
    this.$spinner.showSpinner();
    usersService
      .delete(id)
      .then(() => {
        this.users = this.users.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isReadOnly(): boolean {
    return this.currentUser.role === UserRole.Viewer;
  }

  public get showParentColumns(): boolean {
    return !(this.tenant?.id > 0 || this.subsidiary?.id > 0 || this.endCustomer?.id > 0);
  }
}
