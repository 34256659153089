import axios from 'axios';
import AuthService from '@/services/auth.service';

export function AuthInterceptor(authService: AuthService) {
    axios.interceptors.request.use(async (config) => {
        const tokenResponse = await authService.getAccessToken();
        if (config.headers) {
            config.headers.Authorization = `Bearer ${tokenResponse}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
}
