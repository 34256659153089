import { PriceCategoryMarkupEnum } from './enums/PriceCategoryMarkupEnum';
import { Tenant } from './tenant';

export class EndCustomer {
  public static GetEmpty(): EndCustomer {
    return new EndCustomer(
      0,
      '',
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      '',
      null,
      null,
      null,
      '',
      null,
      null,
      PriceCategoryMarkupEnum.Unknown,
      null,
      null,
      null,
      null,
      null,
      0,
      null,
    );
  }
  public static GetCopy(original: EndCustomer): EndCustomer {
    return new EndCustomer(
      original.id,
      original.name,
      original.tenantId,
      original.email,
      original.foreName,
      original.lastName,
      original.currencyCode,
      original.createdBy,
      original.createdAt,
      original.vatNumber,
      original.tenant,
      original.addressLine1,
      original.addressLine2,
      original.addressZip,
      original.addressTown,
      original.addressCountry,
      original.priceCategory,
      original.invoicingAddressLine1,
      original.invoicingAddressLine2,
      original.invoicingAddressZip,
      original.invoicingAddressTown,
      original.invoicingAddressCountry,
      original.markupId,
      original.subsidiary,
    );
  }
  constructor(
    public id: number,
    public name: string,
    public tenantId: number,
    public email: string | null,
    public foreName: string | null,
    public lastName: string | null,
    public currencyCode: string | null,
    public createdBy: string | null,
    public createdAt: Date | null,
    public vatNumber: string,
    public tenant: Tenant | null,
    public addressLine1: string | null,
    public addressLine2: string | null,
    public addressZip: string | null,
    public addressTown: string | null,
    public addressCountry: string | null,
    public priceCategory: PriceCategoryMarkupEnum,
    public invoicingAddressLine1: string | null,
    public invoicingAddressLine2: string | null,
    public invoicingAddressZip: string | null,
    public invoicingAddressTown: string | null,
    public invoicingAddressCountry: string | null,
    public markupId: number,
    public subsidiary: string | null,
  ) {}
}
