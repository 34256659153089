
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Mode } from '@/store/enums';
    import { PersistentGetters } from '@/store/persistent/enums';
    import Button from '../Button.vue';
    import { AccessLevel } from '@/models/user';
    import { hasPermission } from '@/utils/permissionUtils';
    import { materialGroupsService } from '@/services/materialGroups.service';
    import { MaterialGroup } from '../../models/materialGroup';

    @Component({
        components: {
            'sc-button': Button,
        },
    })
    export default class MaintainMaterialGroup extends Vue {

        @Prop({ required: true, default: 0 })
        public tenantId!: number;

        public materialGroup: MaterialGroup = MaterialGroup.GetEmpty();

        public mode: Mode = Mode.View;

        public form: any = null;
        public modalVisible = false;

        public isEditMode: boolean = this.mode === Mode.Edit;
        public isCreateMode: boolean = this.mode === Mode.Create;
        public isViewMode: boolean = this.mode === Mode.View;

        public get actionTitle() {
            if (this.isCreateMode) {
                return this.$i18n.t('action.create').toString();
            } else if (this.isEditMode) {
                if (this.isReadOnly) {
                    return this.$i18n.t('action.view').toString();
                } else {
                    return this.$i18n.t('action.edit').toString();
                }
            } else {
                return this.$i18n.t('action.close').toString();
            }
        }

        public get isEnabled() {
            if (this.materialGroup && this.$refs.form) {
                const refForm = this.$refs.form as any;
                return refForm.checkValidity();
            }
            return false;
        }

        public handleChange(event: any): void {
            this.materialGroup = { ...this.materialGroup, [event?.target.name]: event?.target.value };
        }

        public async openModalExternal(mode: Mode, editableMaterialGroup: MaterialGroup): Promise<void> {
            if (mode === Mode.Create) {
                this.materialGroup = MaterialGroup.GetEmpty();
                this.materialGroup.tenantId = this.tenantId;
            } else if (mode === Mode.Edit) {
                this.materialGroup = MaterialGroup.GetCopy(editableMaterialGroup as MaterialGroup);
            } else {
                this.materialGroup = editableMaterialGroup as MaterialGroup;
            }

            this.mode = mode;
            this.updateMode();
            await this.openModal();
        }

        public async onSubmit() {
            this.$spinner.showSpinner();

            if (this.isCreateMode) {
                materialGroupsService
                    .create(this.materialGroup)
                    .then(() => {
                        this.modalVisible = false;
                        this.$emit('onClose');
                    })
                    .finally(() => this.$spinner.removeSpinner());
            } else if (this.isEditMode) {
                materialGroupsService
                    .update(this.materialGroup)
                    .then(() => {
                        this.modalVisible = false;
                        this.$emit('onClose');
                    })
                    .finally(() => this.$spinner.removeSpinner());
            }
        }

        private updateMode() {
            this.isEditMode = this.mode === Mode.Edit;
            this.isCreateMode = this.mode === Mode.Create;
            this.isViewMode = this.mode === Mode.View;
        }

        private async openModal(): Promise<void> {
            this.modalVisible = true;
        }

        public get isReadOnly(): boolean {
            return !(this.isSuperAdmin || this.isTenantAdmin);
        }

        public get currentUser() {
            return this.$store.getters[PersistentGetters.CurrentUser];
        }

        public get isSuperAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
        }

        public get isTenantAdmin(): boolean {
            return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
        }
    }
