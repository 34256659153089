import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { Address } from '../models/address';

class AddressesService {

    public getById(id: number): Promise<Address> {
        return axios.get<Address>(`${BaseUrl}/addresses/${id}`).then((response: any) => {
            responseHandler.handleDates(response.data);
            return response.data;
        });
    }

    public create(address: Address) {
        return axios.post(`${BaseUrl}/addresses/`, address).then((response: any) => {
            return response.data;
        });
    }

    public update(address: Address) {
        return axios.put(`${BaseUrl}/addresses/`, address).then((response: any) => {
            return response.data;
        });
    }

    public delete(id: number) {
        return axios.delete(`${BaseUrl}/addresses/${id}`).then((response: any) => {
            return response.data;
        });
    }
}

export const addressesService = new AddressesService();
