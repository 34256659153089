
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Subsidiary } from '@/models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Routes } from '@/router';
import CreateSubsidiary from '../../components/subsidiaries/CreateSubsidiary.vue';
import Button from '@/components/Button.vue';
import { UserRole } from '@/models/user';
import { PersistentGetters } from '@/store/persistent/enums';

@Component({
  components: {
    'sc-create-subsidiary': CreateSubsidiary,
    'sc-button': Button,
  },
})
export default class Subsidiaries extends Vue {
  public subsidiaries: Subsidiary[] = [];
  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;
  public routes = Routes;
  public isEditMode = false;
  public editSubsidiary: Subsidiary = Subsidiary.GetEmpty();

  public confirmDelete(id: number): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('subsidiary.dialog.delete').toString() + ` <b>${id}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteSubsidiary(id),
    });
  }

  public async created() {
    this.$spinner.showSpinner();
    await this.getDevices();
  }

  public async startEdit(subsidiary: Subsidiary) {
    this.$router.push({ name: Routes.SubsidiaryDetail, params: { id: subsidiary.id.toString() } });
  }

  public async modalClose() {
    await this.getDevices();
  }

  private async getDevices() {
    this.subsidiaries = await subsidiariesService.get();
    this.$spinner.removeSpinner();
  }

  private deleteSubsidiary(id: number): void {
    this.$spinner.showSpinner();
    subsidiariesService
      .delete(id)
      .then(() => {
        this.subsidiaries = this.subsidiaries.filter((x) => x.id !== id);
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  public get isReadOnly(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role === UserRole.Viewer;
  }

  public get isSuperAdmin(): boolean {
    return this.$store.getters[PersistentGetters.CurrentUser].role === UserRole.Superadmin;
  }
}
