
import { Component, Vue } from 'vue-property-decorator';
import { Mode } from '@/store/enums';
import { PersistentGetters } from '@/store/persistent/enums';
import Button from '../Button.vue';
import { AccessLevel, UserLanguage } from '@/models/user';
import { Roles, UserBelongsTo } from '@/models/roles';
import { markupsService } from '@/services/markups.service';
import { hasPermission } from '@/utils/permissionUtils';
import { Markup } from '@/models/markup';
import ValidationField from '@/components/inputs/ValidationField.vue';

@Component({
  components: {
    'sc-button': Button,
    'sc-validation-field': ValidationField,
  },
})
export default class MaintainMarkup extends Vue {
  public tenantId: number | null = 0;

  public editableMarkup: Markup = Markup.GetEmpty();
  public markup: Markup = Markup.GetEmpty();

  public mode: Mode = Mode.View;
  public parentType: UserBelongsTo = UserBelongsTo.Subsidiary;
  public hideParentTypeSelection: boolean = false;
  public disableParentTypeSelection: boolean = false;

  public form: any = null;
  public modalVisible = false;

  public isEditMode: boolean = this.mode === Mode.Edit;
  public isCreateMode: boolean = this.mode === Mode.Create;
  public isViewMode: boolean = this.mode === Mode.View;

  public forceValidationSeed: number = 0;
  public forcedValidationInterval: any = null;

  public get roles() {
    const allowedKeys = Object.keys(Roles)
      .filter((item) => {
        return !isNaN(Number(item)) && (this.isSuperAdmin || item !== '1') && (this.isEndCustomerAdmin || item !== '4');
      })
      .map((item) => Number(item));

    return allowedKeys;
  }

  public userLanguages = Object.keys(UserLanguage)
    .filter((item) => {
      const value = Number(item);
      return !isNaN(value) && value > 0;
    })
    .map((item) => Number(item));

  public get actionTitle() {
    if (this.isCreateMode) {
      return this.$i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      if (this.isReadOnly) {
        return this.$i18n.t('action.view').toString();
      } else {
        return this.$i18n.t('action.edit').toString();
      }
    } else {
      return this.$i18n.t('action.close').toString();
    }
  }

  public get isEnabled() {
    if (this.markup && this.$refs.form && this.forceValidationSeed > 0) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public get isTenantScope() {
    return this.tenantId && this.tenantId > 0;
  }

  public valueChanged() {
    setTimeout(() => {
      this.forceValidationSeed = new Date().getTime();
    }, 0);
  }

  public handleChange(event: any): void {
    this.markup = { ...this.markup, [event?.target.name]: event?.target.value };
  }

  public async openModalExternal(mode: Mode, editableMarkup?: Markup, tenantId?: number): Promise<void> {
    if (mode === Mode.Create) {
      this.markup = Markup.GetEmpty();
    } else if (mode === Mode.Edit) {
      this.markup = Markup.GetCopy(editableMarkup as Markup);
    } else {
      this.markup = editableMarkup as Markup;
    }

    this.mode = mode;
    this.tenantId = tenantId ?? null;
    this.updateMode();
    if (this.tenantId) {
      this.markup.tenantId = this.tenantId;
    }
    await this.openModal();
  }

  public async onSubmit() {
    this.$spinner.showSpinner();

    if (this.isCreateMode) {
      markupsService
        .create(this.markup)
        .then(() => {
          this.modalVisible = false;
          this.$emit('onClose');
        })
        .finally(() => this.$spinner.removeSpinner());
    } else if (this.isEditMode) {
      markupsService
        .update(this.markup)
        .then(() => {
          this.modalVisible = false;
          this.$emit('onClose');
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private updateMode() {
    this.isEditMode = this.mode === Mode.Edit;
    this.isCreateMode = this.mode === Mode.Create;
    this.isViewMode = this.mode === Mode.View;
  }

  private async openModal(): Promise<void> {
    this.modalVisible = true;
  }

  public get isReadOnly(): boolean {
    return !(this.isSuperAdmin || (this.isTenantScope === true && this.isTenantAdmin));
  }

  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isSuperAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
  }

  public get isSubsidiaryAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.SubsidiaryAdmin);
  }

  public get isTenantAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
  }

  public get isEndCustomerAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.EndCustomerAdmin);
  }
}
