import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Measurement } from '@/models/measurement';
import { CurrencyCode } from '@/models/currencyCode';
import { TimeZone } from '@/models/timeZone';

class MastersService {
  public getServices(): Promise<Measurement[]> {
    return axios.get<Measurement[]>(`${BaseUrl}/masters/services`)
    .then((response: any) => {
      return response.data;
    });
  }

  public getCurrencyCodes(): Promise<CurrencyCode[]> {
    return axios.get<CurrencyCode[]>(`${BaseUrl}/masters/currency-codes`)
    .then((response: any) => {
      return response.data;
    });
  }

  public getMeasurementUnits(): Promise<Measurement[]> {
    return axios.get<Measurement[]>(`${BaseUrl}/masters/measurement-units`)
    .then((response: any) => {
      return response.data;
    });
  }

  public getTimezones(): Promise<TimeZone[]> {
    return axios.get<TimeZone[]>(`${BaseUrl}/masters/timezones`)
    .then((response: any) => {
      return response.data;
    });
  }
}

export const mastersService = new MastersService();
