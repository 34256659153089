import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { User } from '@/models/user';
import i18n from '@/i18n';

class UsersService {
  public get(): Promise<User[]> {
    return axios.get<User[]>(`${BaseUrl}/users`).then((response: any) => {
      return response.data;
    });
  }

  public delete(id: number): Promise<User[]> {
    return axios.delete(`${BaseUrl}/users/${id}`).then((response: any) => {
      return response.data;
    });
  }

  public update(user: User) {
    return axios.put(`${BaseUrl}/users/`, user).then((response: any) => {
      return response.data;
    });
  }

  public create(user: User) {
    return axios.post(`${BaseUrl}/users/`, user).then((response: any) => {
      return response.data;
    });
  }

  public me(): Promise<User[]> {
    return axios.get<User>(`${BaseUrl}/users/me`).then((response: any) => {
      return response.data;
    });
  }

  public getById(id: number): Promise<User> {
    return axios.get<User>(`${BaseUrl}/users/${id}`).then((response: any) => {
      return response.data;
    });
  }

  public async getTermsTxt(lang: string): Promise<string> {
    const response = await axios.get(`${BaseUrl}/users/termstxt/${lang}`);
    return response.data;
  }

  public async terms(lang: string) {
    const response = await axios.get(`${BaseUrl}/users/terms/${lang}`, { responseType: 'arraybuffer' });
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', i18n.t('terms.terms_and_conditions').toString());
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response;
  }

  public resetTermsAcceptance() {
    return axios.get(`${BaseUrl}/users/reset-terms-acceptance/`).then((response) => {
      return response.data;
    });
  }

}

export const usersService = new UsersService();
