import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { Material } from '@/models/material';
import { MaterialFormat } from '@/models/materialFormat';

class MaterialsService {
    public getById(id: number): Promise<Material> {
        return axios.get<Material>(`${BaseUrl}/materials/${id}`).then((response: any) => {
            responseHandler.handleDates(response.data);
            return response.data;
        });
    }

    public create(material: Material) {
        return axios.post(`${BaseUrl}/materials/`, material).then((response: any) => {
            return response.data;
        });
    }

    public update(material: Material) {
        return axios.put(`${BaseUrl}/materials/`, material).then((response: any) => {
            return response.data;
        });
    }

    public delete(id: number) {
        return axios.delete(`${BaseUrl}/materials/${id}`).then((response: any) => {
            return response.data;
        });
    }

    public getMaterialFormats(id: number): Promise<MaterialFormat[]> {
        return axios.get(`${BaseUrl}/materials/${id}/materialformats`).then((response: any) => {
            return response.data;
        });
    }
}

export const materialsService = new MaterialsService();
