
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { tenantsService } from '@/services/tenants.service';
@Component
export default class TenantsSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: Tenant[] = [];

  public mounted(): void {
    this.getItems();
  }
  public async getItems() {
    this.$spinner.showSpinner();
    this.items = await tenantsService.get();
    this.$spinner.removeSpinner();
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
