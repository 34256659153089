import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { User } from '../models/user';
import { EndCustomer } from '../models/endCustomer';
import { Address } from '../models/address';
import { AddressTypeEnum } from '../models/enums/AddressTypeEnum';

class EndCustomerService {
  public get(): Promise<EndCustomer[]> {
    return axios.get<EndCustomer[]>(`${BaseUrl}/endcustomers`).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public getByTenant(tenantId: number): Promise<EndCustomer[]> {
    return axios.get<EndCustomer[]>(`${BaseUrl}/endcustomers/getbytenant/` + tenantId).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public getById(id: number): Promise<EndCustomer> {
    return axios.get<EndCustomer>(`${BaseUrl}/endcustomers/${id}`).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public delete(id: number): Promise<EndCustomer[]> {
    return axios.delete(`${BaseUrl}/endcustomers/${id}`).then((response: any) => {
      return response.data;
    });
  }

  public update(endCustomer: EndCustomer) {
    return axios.put(`${BaseUrl}/endcustomers/`, endCustomer).then((response: any) => {
      return response.data;
    });
  }

  public create(endCustomer: EndCustomer) {
    return axios.post(`${BaseUrl}/endcustomers/`, endCustomer).then((response: any) => {
      return response.data;
    });
  }

  public getUsers(id: number): Promise<User[]> {
    return axios.get(`${BaseUrl}/endcustomers/${id}/users`).then((response: any) => {
      return response.data;
    });
  }

  public getMaterialGroups(id: number) {
    return axios.get(`${BaseUrl}/endcustomers/${id}/group-materials`).then((response: any) => {
      return response.data;
    });
  }

  public getTechnologies(id: number) {
    return axios.get(`${BaseUrl}/endcustomers/${id}/technologies`).then((response: any) => {
      return response.data;
    });
  }

  public getRejectReasons(id: number) {
    return axios.get(`${BaseUrl}/endcustomers/${id}/reject-reasons`).then((response: any) => {
      return response.data;
    });
  }

    public getAddresses(id: number): Promise<Address[]> {
     return axios.get(`${BaseUrl}/endcustomers/${id}/addresses`).then((response: any) => {
        return response.data;
     });
  }
}

export const endCustomersService = new EndCustomerService();
