export class Tenant {
  public static GetEmpty(): Tenant {
    return new Tenant(
      0,
      '',
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      '',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    );
  }
  public static GetCopy(original: Tenant): Tenant {
    return new Tenant(
      original.id,
      original.name,
      original.tenantId,
      original.email,
      original.foreName,
      original.lastName,
      original.sapNumber,
      original.currencyCode,
      original.measurement,
      original.timeZone,
      original.subsidiaryId,
      original.createdBy,
      original.createdAt,
      original.vatNumber,
      original.services,
      original.subsidiary,
      original.isIntegratedWithBysoftBusiness,
      original.addressLine1,
      original.addressLine2,
      original.addressZip,
      original.addressTown,
      original.addressCountry,
      original.integrationServicesApiKey,
      original.quoteExpirationDays,
      original.acceptQuoteUrl,
      original.rejectQuoteUrl,
      original.currencyAbbreviation,
      original.storageAccountUrl,
      original.storageAccountName,
      original.storageAccountContainerName,
      original.storageAccountAccessKey,
      original.storageAccountCompanySubFolder,
      original.storageAccountEnvironmentSubFolder,
      original.defaultServicesHost,
      original.servicesHost,
      original.servicesVersion,
      original.businessCentralId,
      original.businessCentralCompanyId,
      original.businessCentralClientId,
      original.businessCentralClientSecret,
    );
  }
  constructor(
    public id: number,
    public name: string,
    public tenantId: number,
    public email: string | null,
    public foreName: string | null,
    public lastName: string | null,
    public sapNumber: string | null,
    public currencyCode: string | null,
    public measurement: string | null,
    public timeZone: string | null,
    public subsidiaryId: number | null,
    public createdBy: string | null,
    public createdAt: Date | null,
    public vatNumber: string,
    public services: number | null,
    public subsidiary: string | null,
    public isIntegratedWithBysoftBusiness: boolean | null,
    public addressLine1: string | null,
    public addressLine2: string | null,
    public addressZip: string | null,
    public addressTown: string | null,
    public addressCountry: string | null,
    public integrationServicesApiKey: string | null,
    public quoteExpirationDays: number | null,
    public acceptQuoteUrl: string | null,
    public rejectQuoteUrl: string | null,
    public currencyAbbreviation: string | null,
    public storageAccountUrl: string | null,
    public storageAccountName: string | null,
    public storageAccountContainerName: string | null,
    public storageAccountAccessKey: string | null,
    public storageAccountCompanySubFolder: string | null,
    public storageAccountEnvironmentSubFolder: string | null,
    public defaultServicesHost: boolean | null,
    public servicesHost: string | null,
    public servicesVersion: string | null,
    public businessCentralId: string | null,
    public businessCentralCompanyId: string | null,
    public businessCentralClientId: string | null,
    public businessCentralClientSecret: string | null,
  ) {}
}
