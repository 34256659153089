
import { Component, Vue } from 'vue-property-decorator';
import { LoginActions } from '@/models/enums/AuthenticationActions';
import { Routes } from '@/router';
import { usersService } from '@/services/usersService';
import { RootActions } from '@/store/enums';
import { PersistentActions } from '@/store/persistent/enums';
import { BasePath } from '@/models/constants';

@Component
export default class Login extends Vue {
  public async mounted() {
    switch (this.$route.name) {
      case LoginActions.Login:
        await this.login(this.getReturnUrl());
        break;
      case LoginActions.LoginCallback:
        await this.processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        this.$router.push({ name: Routes.Unauthorized });
        break;
      case LoginActions.Profile:
        this.redirectToProfile();
        break;
      case LoginActions.Register:
        this.redirectToRegister();
        break;
      default:
        throw new Error(`Invalid action '${this.$route.path}'`);
    }
  }

  private async login(returnUrl: string): Promise<void> {
    await this.$auth.login();
  }

  private async processLoginCallback(): Promise<void> {
    const url = window.location.href;
    this.$auth
      .completeSignIn(url)
      .then((result) => {
        if (result) {
          usersService.me().then((user) => {
            this.$store.dispatch(PersistentActions.SetCurrentUser, user);
          })
          .finally(() => this.navigateToReturnUrl(this.getReturnUrl(result.state)));
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  private redirectToRegister(): any {
    this.redirectToApiAuthorizationPath(
      `/Identity/Account/Register?returnUrl=${encodeURI('/authentication/' + LoginActions.Login)}`,
    );
  }

  private redirectToProfile(): void {
    this.redirectToApiAuthorizationPath('/Identity/Account/Manage');
  }

  private async navigateToReturnUrl(returnUrl: string) {
    window.location.replace(returnUrl);
  }

  private getReturnUrl(state?: any): string {
    const fromQuery = this.$route.params.returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (fromQuery && !(fromQuery.startsWith(`${window.location.origin}/`) || /\/[^\/].*/.test(fromQuery))) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return (state && state.returnUrl) || fromQuery || BasePath;
  }

  private redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
    window.location.replace(redirectUrl);
  }
}
