
import { Component, Vue } from 'vue-property-decorator';
import SubsidiariesSelect from '../inputs/SubsidiariesSelect.vue';
import TenantsSelect from '../inputs/TenantsSelect.vue';
import { Mode } from '@/store/enums';
import { PersistentGetters } from '@/store/persistent/enums';
import Button from '../Button.vue';
import { AccessLevel, User, UserLanguage, UserRole, UserType } from '@/models/user';
import { Roles, UserBelongsTo } from '@/models/roles';
import { usersService } from '@/services/usersService';
import { hasPermission } from '@/utils/permissionUtils';
import EndCustomerSelect from '../inputs/EndCustomerSelect.vue';
import ValidationField from '@/components/inputs/ValidationField.vue';

@Component({
  components: {
    'sc-subsidiaries-select': SubsidiariesSelect,
    'sc-tenants-select': TenantsSelect,
    'sc-end-customers-select': EndCustomerSelect,
    'sc-button': Button,
    'sc-validation-field': ValidationField,
  },
})
export default class MaintainUser2 extends Vue {
  public subsididaryId: number | null = 0;
  public tenantId: number | null = 0;
  public endCustomerId: number | null = 0;

  public editableUser: User = User.GetEmpty();
  public user: User = User.GetEmpty();

  public mode: Mode = Mode.View;
  public parentType: UserBelongsTo = UserBelongsTo.Subsidiary;
  public hideParentTypeSelection: boolean = false;
  public disableParentTypeSelection: boolean = false;

  public form: any = null;
  public modalVisible = false;
  public isRoleDisabled: boolean = false;

  public isEditMode: boolean = this.mode === Mode.Edit;
  public isCreateMode: boolean = this.mode === Mode.Create;
  public isViewMode: boolean = this.mode === Mode.View;

  public forceValidationSeed: number = 0;
  public forcedValidationInterval: any = null;

  public get roles() {
    this.isRoleDisabled = false;

    let allowedKeys = Object.keys(Roles)
      .filter((item) => {
        return !isNaN(Number(item)) && (this.isSuperAdmin || item !== '1') &&
            (this.isEndCustomerAdmin || item !== '4');
      })
      .map((item) => Number(item));

    if (!this.isViewMode && !this.isEditMode && !this.isSubsidiaryScope &&
      !this.isTenantScope && !this.isEndCustomerScope) {
      allowedKeys = allowedKeys.filter((item) => {
        this.user.role = 1;
        this.isRoleDisabled = true;
        return item === UserBelongsTo.Subsidiary;
      });
    }

    return allowedKeys;
  }

  public userTypes = Object.keys(UserType)
    .filter((item) => {
      const value = Number(item);
      return !isNaN(value) && value > 0;
    })
    .map((item) => Number(item));

  public get parentTypes() {
    let allowedKeys = Object.keys(UserBelongsTo)
      .filter((item) => {
        return !isNaN(Number(item)) && (!this.isGlobalRoleSelected || (this.isGlobalRoleSelected && item === '3'));
      })
      .map((item) => Number(item));
    if (this.isSubsidiaryScope) {
      allowedKeys = allowedKeys.filter((item) => {
        return item === UserBelongsTo.Subsidiary;
      });
    }

    return allowedKeys;
  }

  public userLanguages = Object.keys(UserLanguage)
    .filter((item) => {
      const value = Number(item);
      return !isNaN(value) && value > 0;
    })
    .map((item) => Number(item));

  public get actionTitle() {
    if (this.isCreateMode) {
      return this.$i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      if (this.isReadOnly) {
        return this.$i18n.t('action.view').toString();
      } else {
        return this.$i18n.t('action.edit').toString();
      }
    } else {
      return this.$i18n.t('action.close').toString();
    }
  }

  public get isEnabled() {
    if (this.user && this.$refs.form && this.forceValidationSeed > 0) {
      const refForm = this.$refs.form as any;
      return (
        refForm.checkValidity() &&
        // weird validation not triggering when showing or v-if, force
        (this.user.type !== 1 || (this.user.type === 1 && this.user.password.length > 0))
      );
    }
    return false;
  }

  public get isGlobalRoleSelected() {
    return this.user.role === UserRole.Global;
  }

  public get isSubsidiaryScope() {
    return this.subsididaryId && this.subsididaryId > 0;
  }

  public get isTenantScope() {
    return this.tenantId && this.tenantId > 0;
  }

  public get isEndCustomerScope() {
    return this.endCustomerId && this.endCustomerId > 0;
  }

  public valueChanged() {
    setTimeout(() => {
      this.forceValidationSeed = new Date().getTime();
    }, 0);
  }

  public handleChange(event: any): void {
    this.user = { ...this.user, [event?.target.name]: event?.target.value };
  }

  public async openModalExternal(
    mode: Mode,
    editableUser?: User,
    subsidiaryId?: number,
    tenantId?: number,
    endCustomerId?: number,
  ): Promise<void> {
    if (mode === Mode.Create) {
      this.user = User.GetEmpty();
    } else if (mode === Mode.Edit) {
      this.user = User.GetCopy(editableUser as User);
    } else {
      this.user = editableUser as User;
    }

    this.mode = mode;
    this.subsididaryId = subsidiaryId ?? null;
    this.tenantId = tenantId ?? null;
    this.endCustomerId = endCustomerId ?? null;
    this.updateMode();
    this.updateUserBelongsTo(mode, editableUser, subsidiaryId, tenantId, endCustomerId);
    await this.openModal();
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    this.cleanseIntermediateFields();

    if (this.isCreateMode) {
      usersService
        .create(this.user)
        .then(() => {
          this.modalVisible = false;
          this.$emit('onClose');
        })
        .finally(() => this.$spinner.removeSpinner());
    } else if (this.isEditMode) {
      usersService
        .update(this.user)
        .then(() => {
          this.modalVisible = false;
          this.$emit('onClose');
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  private updateMode() {
    this.isEditMode = this.mode === Mode.Edit;
    this.isCreateMode = this.mode === Mode.Create;
    this.isViewMode = this.mode === Mode.View;
  }

  private updateUserBelongsTo(
    mode: Mode,
    user?: User,
    subsidiaryId?: number,
    tenantId?: number,
    endCustomerId?: number,
  ) {
    if (mode === Mode.Create) {
      if (subsidiaryId) {
        this.user.parentType = UserBelongsTo.Subsidiary;
        this.user.subsidiaryId = this.subsididaryId;
        this.hideParentTypeSelection = true;
        this.disableParentTypeSelection = true;
      } else if (tenantId) {
        this.user.parentType = UserBelongsTo.Tenant;
        this.user.tenantId = this.tenantId;
        this.hideParentTypeSelection = true;
        this.disableParentTypeSelection = true;
      } else if (endCustomerId) {
        this.user.parentType = UserBelongsTo.EndCustomer;
        this.user.endCustomerId = this.endCustomerId;
        this.hideParentTypeSelection = true;
        this.disableParentTypeSelection = true;
      } else {
        this.hideParentTypeSelection = false;
        this.disableParentTypeSelection = false;
      }
    } else {
      if (user?.subsidiaryId && user.subsidiaryId > 0) {
        this.user.parentType = UserBelongsTo.Subsidiary;
      } else if (user?.tenantId && user.tenantId > 0) {
        this.user.parentType = UserBelongsTo.Tenant;
      } else if (user?.endCustomerId && user.endCustomerId > 0) {
        this.user.parentType = UserBelongsTo.EndCustomer;
      }

      this.hideParentTypeSelection = false;
      this.disableParentTypeSelection = true;
    }
  }

  private async openModal(): Promise<void> {
    this.modalVisible = true;
  }

  private cleanseIntermediateFields() {
    if (this.user.role === UserRole.Superadmin) {
      this.user.tenantId = null;
      this.user.subsidiaryId = null;
    } else if (this.user.parentType === UserBelongsTo.Subsidiary) {
      this.user.tenantId = null;
      this.user.endCustomerId = null;
    } else if (this.user.parentType === UserBelongsTo.Tenant) {
      this.user.subsidiaryId = null;
      this.user.endCustomerId = null;
    } else if (this.user.parentType === UserBelongsTo.EndCustomer) {
      this.user.tenantId = null;
      this.user.subsidiaryId = null;
    }
  }

  public get isReadOnly(): boolean {
    return !(
      this.isSuperAdmin ||
      (this.isSubsidiaryScope === true && this.isSubsidiaryAdmin) ||
      (this.isTenantScope === true && this.isTenantAdmin)
    );
  }

  public get isEmailReadOnly(): boolean {
    return !(
      this.isSuperAdmin ||
      (this.isSubsidiaryScope === true && this.isSubsidiaryAdmin) ||
      (this.isTenantScope === true && this.isTenantAdmin)
    );
  }

  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isSuperAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
  }

  public get isSubsidiaryAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.SubsidiaryAdmin);
  }

  public get isTenantAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
  }

  public get isEndCustomerAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.EndCustomerAdmin);
  }

  public get belongsToSubsidiary(): boolean {
    return Number(this.user.parentType) === UserBelongsTo.Subsidiary;
  }

  public get belongsToTenant(): boolean {
    return Number(this.user.parentType) === UserBelongsTo.Tenant;
  }

  public get belongsToEndCustomer(): boolean {
    return Number(this.user.parentType) === UserBelongsTo.EndCustomer;
  }

  public get getUserParentType() {
    let parentType: any = '';

    if (this.user && this.user.parentType) {
      parentType = this.$t(`user_parent_type.${this.user.parentType}`);
    }

    return parentType;
  }

}
