
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import Button from '@/components/Button.vue';
import MaintainShipping from '@/components/shippings/MaintainShipping.vue';
import { Shipping } from '@/models/shipping';
import { Mode } from '@/store/enums';
import { tenantsService } from '@/services/tenants.service';
import { shippingsService } from '@/services/shippings.service';
import { Routes } from '@/router';
import { PersistentGetters } from '@/store/persistent/enums';
import { UserRole } from '@/models/user';

@Component({
  components: {
    'sc-button': Button,
    'sc-maintain-shipping': MaintainShipping,
  },
})
export default class ShippingsWithParent extends Vue {
  @Prop({ required: true })
  public tenant!: Tenant;

  @Ref('maintainShipping')
  public maintainShipping!: MaintainShipping;

  public isPaginationSimple = false;
  public paginationPosition = 'bottom';
  public defaultSortDirection = 'asc';
  public sortIcon = 'arrow-up';
  public sortIconSize = 'is-small';
  public currentPage = 1;
  public perPage = 10;
  public isPaginated = true;
  public routes = Routes;

  public shippings: Shipping[] = [];

  public async created() {
    await this.getShippings();
  }

  public async startCreate() {
     this.maintainShipping.openModalExternal(
      Mode.Create,
      Shipping.GetEmpty(),
     );
  }

  public async modalClose() {
            await this.getShippings();
        }

  public async createShipping() {
    this.maintainShipping.openModalExternal(
        Mode.Create,
        Shipping.GetEmpty(),
    );
  }

  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isReadOnly(): boolean {
    return this.currentUser.role === UserRole.Viewer;
  }

  public async editShipping(shipping: Shipping) {
    this.$spinner.showSpinner();
    const editableShipping: Shipping = await shippingsService.getById(shipping.id);
    this.$spinner.removeSpinner();

    this.maintainShipping.openModalExternal(
        this.isReadOnly ? Mode.View : Mode.Edit,
        editableShipping);
  }

  public confirmDelete(id: number, name: string): void {
    this.$buefy.dialog.confirm({
      title: this.$i18n.t('dialog.confirm_title').toString(),
      message: this.$i18n.t('shipping.dialog.delete').toString() + ` <b>${name}</b>?`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteShipping(id),
    });
  }

protected deleteShipping(id: number): void {
            this.$spinner.showSpinner();
            shippingsService
               .delete(id)
               .then(() => {
                   this.shippings = this.shippings.filter((x) => x.id !== id);
               })
               .finally(() => {
                   this.$spinner.removeSpinner();
               });
        }

  public get hasTenant(): boolean {
    return this.tenant.id > 0;
  }

public get deleteShippingMessage(): string {
            const message = this.$i18n.t('shipping.dialog.delete').toString();
            return message;
        }

private get weightUnit() {
            if (this.tenant.measurement && this.tenant.measurement === '2') {
                return this.$i18n.t(`units.weight.pound.abbreviation`);
            } else if (this.tenant.measurement && this.tenant.measurement === '1') {
                return this.$i18n.t(`units.weight.kilo.abbreviation`);
            }
            return '';
        }

private async getShippings() {
    this.$spinner.showSpinner();
    if (this.hasTenant) {
      this.shippings = await tenantsService.getShippings(this.tenant.id);
    }
    this.$spinner.removeSpinner();
  }
}

