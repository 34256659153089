
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Unauthorized extends Vue {
  public contactUrl: string = '';
  public created() {
    this.contactUrl = JSON.parse(sessionStorage.getItem('spa.config') || '').administratorContactUrl;
  }
}
