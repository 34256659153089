export class Shipping {
  public static GetEmpty(): Shipping {
    return new Shipping(0, '', '', '', null, null, 0, null);
  }
  public static GetCopy(original: Shipping): Shipping {
    return new Shipping(original.id,
                        original.name,
                        original.description,
                        original.leadTime,
                        original.fixedCost,
                        original.costPerWeight,
                        original.tenantId,
                        original.leadTimeDisplay);
  }
  constructor(public id: number,
              public name: string,
              public description: string,
              public leadTime: string,
              public fixedCost: number | null,
              public costPerWeight: number | null,
              public tenantId: number,
              public leadTimeDisplay: string | null) {}
}
