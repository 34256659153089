
import { Address } from '@/models/address';
import { Component, Vue, Prop } from 'vue-property-decorator';
import QuoteElement from './QuoteElement.vue';

@Component({
  components: {
    'sc-quote-element': QuoteElement,
  },
})
export default class LeadTimeInfo extends Vue {

    @Prop({required: false})
    public shippingAddress!: Address;

    @Prop({required: false})
    public totalPrice!: number;

}
