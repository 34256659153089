import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { ConfigurationSpa } from '@/models/configurationSpa';

class ConfigurationService {
  public getConfig(): Promise<ConfigurationSpa> {
    return axios.get<ConfigurationSpa>(`${BaseUrl}/spa-configuration`)
      .then((response: any) => {
        return response.data;
      });
  }
}

export const configurationService = new ConfigurationService();
