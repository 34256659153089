import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { Technology } from '../models/technology';

class TechnologiesService {

    public getById(id: number): Promise<Technology> {
        return axios.get<Technology>(`${BaseUrl}/technologies/${id}`).then((response: any) => {
            responseHandler.handleDates(response.data);
            return response.data;
        });
    }

    public create(technology: Technology) {
        return axios.post(`${BaseUrl}/technologies/`, technology).then((response: any) => {
            return response.data;
        });
    }

    public update(technology: Technology) {
        return axios.put(`${BaseUrl}/technologies/`, technology).then((response: any) => {
            return response.data;
        });
    }

    public delete(id: number) {
        return axios.delete(`${BaseUrl}/technologies/${id}`).then((response: any) => {
            return response.data;
        });
    }
}

export const technologiesService = new TechnologiesService();
