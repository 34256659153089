
import { Vue, Prop, Component } from 'vue-property-decorator';
import Button from '../Button.vue';
import { Shipping } from '@/models/shipping';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/store/enums';
import { shippingsService } from '@/services/shippings.service';
import { hasPermission } from '@/utils/permissionUtils';
import { PersistentGetters } from '@/store/persistent/enums';
import { AccessLevel } from '@/models/user';
import ValidationField from '@/components/inputs/ValidationField.vue';
import { configurationService } from '@/services/configuration.service';

@Component({
  components: {
    'sc-button': Button,
    'sc-validation-field': ValidationField,
  },
})
export default class MaintainShipping extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  public tenant!: Tenant;

  public shipping: Shipping = Shipping.GetEmpty();

  public mode: Mode = Mode.View;

  public form: any = null;
  public modalVisible = false;

  public isEditMode: boolean = this.mode === Mode.Edit;
  public isCreateMode: boolean = this.mode === Mode.Create;
  public isViewMode: boolean = this.mode === Mode.View;

  public forceValidationSeed: number = 0;
  public forcedValidationInterval: any = null;
  public leadTimeFormatPattern: string = '';

  public get actionTitle() {
    if (this.isCreateMode) {
      return this.$i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      if (this.isReadOnly) {
        return this.$i18n.t('action.view').toString();
      } else {
        return this.$i18n.t('action.edit').toString();
      }
    } else {
      return this.$i18n.t('action.close').toString();
    }
  }

public get isEnabled() {
            if (this.shipping && this.$refs.form) {
                const refForm = this.$refs.form as any;
                return refForm.checkValidity();
            }
            return false;
        }

  public handleChange(event: any): void {
    this.shipping = { ...this.shipping, [event?.target.name]: event?.target.value };
  }

  public async openModalExternal(mode: Mode, editableShipping: Shipping): Promise<void> {
    if (mode === Mode.Create) {
      this.shipping = Shipping.GetEmpty();
      this.shipping.tenantId = this.tenantId;
    } else if (mode === Mode.Edit) {
      this.shipping = Shipping.GetCopy(editableShipping as Shipping);
    } else {
      this.shipping = editableShipping as Shipping;
    }

    this.mode = mode;
    this.updateMode();
    await this.openModal();
  }

  public async onSubmit() {
    this.$spinner.showSpinner();

    if (this.isCreateMode) {
      shippingsService
        .create(this.shipping)
        .then(() => {
          this.modalVisible = false;
          this.$emit('onClose');
        })
        .finally(() => this.$spinner.removeSpinner());
    } else if (this.isEditMode) {
      shippingsService
        .update(this.shipping)
        .then(() => {
          this.modalVisible = false;
          this.$emit('onClose');
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  public get isReadOnly(): boolean {
    return !(this.isSuperAdmin || this.isTenantAdmin);
  }

  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  public get isSuperAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.SuperAdmin);
  }

  public get isTenantAdmin(): boolean {
    return hasPermission(this.currentUser, AccessLevel.TenantAdmin);
  }

  public valueChanged() {
    setTimeout(() => {
      this.forceValidationSeed = new Date().getTime();
    }, 0);
  }

  private get fixedCostLabel() {
    return `${this.$i18n.t(`shipping.fixedCost`)} (${this.tenant.currencyAbbreviation})`;
  }

  private get costPerWeightLabel() {
    return `${this.$i18n.t(`shipping.costPerWeight`)} (${this.tenant.currencyAbbreviation}/${this.weightUnit})`;
  }

  private get weightUnit() {
    if (this.tenant.measurement && this.tenant.measurement === '2') {
      return this.$i18n.t(`units.weight.pound.abbreviation`);
    }

    return this.$i18n.t(`units.weight.kilo.abbreviation`);
  }

  private updateMode() {
    this.isEditMode = this.mode === Mode.Edit;
    this.isCreateMode = this.mode === Mode.Create;
    this.isViewMode = this.mode === Mode.View;
  }

  private async openModal(): Promise<void> {
    this.modalVisible = true;
  }

  private get tenantId() {
    return this.tenant.id;
  }
}
