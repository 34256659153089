import { ConfigurationSpa } from '@/models/configurationSpa';
import { UserManager, User, WebStorageStateStore } from 'oidc-client';

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    const spaConfig: ConfigurationSpa = JSON.parse(sessionStorage.getItem('spa.config') || '');

    const userManagerSettings: any = spaConfig.oidcConfiguration;
    userManagerSettings.userStore = new WebStorageStateStore({ store: window.localStorage });
    userManagerSettings.automaticSilentRenew = true;
    userManagerSettings.includeIdTokenInSilentRenew = true;

    this.userManager = new UserManager(userManagerSettings);
  }

  public async getUser(): Promise<User | null> {
    return await this.userManager.getUser();
  }

  public async login(): Promise<void> {
    return await this.userManager.signinRedirect();
  }

  public async completeSignIn(url: string): Promise<User> {
      return await this.userManager.signinCallback(url);
  }

  public async logout(): Promise<void> {
    return await this.userManager.signoutRedirect();
  }

  public async getAccessToken(): Promise<string> {
    return this.userManager.getUser().then((data: any) => {
      return data?.access_token;
    });
  }

  public async isLoggedIn(): Promise<boolean> {
    const user = await this.userManager.getUser();
    if (!user || user.expired === undefined) {
      return false;
    }

    return !user.expired;
  }

}
