
import { Routes } from '@/router';
import { PersistentGetters } from '@/store/persistent/enums';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Home extends Vue {
  public mounted() {
    if (this.currentUser.endCustomerId && this.currentUser.endCustomerId > 0) {
      this.$router.push(Routes.Dashboard);
    }
  }


  public get currentUser() {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }
}
