import Vue from 'vue';
import App from '@/App.vue';
import router, { Routes } from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import VueAppInsights from 'vue-application-insights';
import { configurationService } from '@/services/configuration.service';
import * as AxiosForbiddenInterceptor from '@/interceptors/axios-interceptor-error';
import * as AxiosAuthInterceptor from '@/interceptors/axios-interceptor-auth';
import * as AxiosLocalizationInterceptor from '@/interceptors/axios-interceptor-localization';
import { ConfigurationSpa } from '@/models/configurationSpa';
import { authServicePlugin } from './plugins/auth.service.plugin';
import {
  Button,
  Navbar,
  Menu,
  Icon,
  Table,
  Select,
  Field,
  Switch,
  Toast,
  Dialog,
  Input,
  Modal,
  Datetimepicker,
  Numberinput,
  Tabs,
  Checkbox,
  Sidebar,
  Datepicker,
  Progress,
  Collapse,
  Timepicker,
  Carousel,
  Upload,
  Message,
  Tooltip,
} from 'buefy';
import { spinnerPlugin } from '@/plugins/spinner.plugin';
import { PersistentGetters } from './store/persistent/enums';
import '@/plugins/validator.plugin';

const hasConfig = sessionStorage.getItem('spa.config');
if (!hasConfig) {
    configurationService.getConfig().then((response: ConfigurationSpa) => {
    sessionStorage.setItem('spa.config', JSON.stringify(response));
    initApp();
  });
} else {
  initApp();
}

function initApp(): void {
  Vue.config.productionTip = false;
  AddBuefyComponents();

  const spaConfig: ConfigurationSpa = JSON.parse(sessionStorage.getItem('spa.config') || '');

  Vue.use(authServicePlugin);
  Vue.use(spinnerPlugin);
  Vue.use(require('vue-moment'));

  Vue.use(VueAppInsights, {
    id: spaConfig.instrumentationKeyAppInsight,
    router,
  });

  const app = new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
    created() {
      AxiosAuthInterceptor.AuthInterceptor(this.$auth);
      AxiosLocalizationInterceptor.LocalizationInterceptor();
      AxiosForbiddenInterceptor.ResponseErrorInterceptor(this.$appInsights);
      i18n.locale = store.getters[PersistentGetters.GetLocale] || window.navigator.language;
    },
  });

  app.$mount('#app');

  Vue.config.errorHandler = (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    app.$appInsights.trackException(err, vm, info);
  };
}

function AddBuefyComponents() {
  Vue.use(Navbar);
  Vue.use(Menu);
  Vue.use(Icon);
  Vue.use(Table);
  Vue.use(Select);
  Vue.use(Field);
  Vue.use(Switch);
  Vue.use(Button);
  Vue.use(Toast);
  Vue.use(Dialog);
  Vue.use(Input);
  Vue.use(Modal);
  Vue.use(Numberinput);
  Vue.use(Datetimepicker);
  Vue.use(Tabs);
  Vue.use(Checkbox);
  Vue.use(Sidebar);
  Vue.use(Datepicker);
  Vue.use(Progress);
  Vue.use(Collapse);
  Vue.use(Timepicker);
  Vue.use(Carousel);
  Vue.use(Upload);
  Vue.use(Message);
  Vue.use(Tooltip);
}
