import { Material } from './material';

export class MaterialGroup {

    public static GetEmpty(): MaterialGroup {
        return new MaterialGroup(0, 0, null, null, null);
    }

    public static GetCopy(original: MaterialGroup): MaterialGroup {
        return new MaterialGroup(
            original.id,
            original.tenantId,
            original.name,
            original.description,
            original.materials,
        );
    }

    constructor(
        public id: number,
        public tenantId: number,
        public name: string | null,
        public description: string | null,
        public materials: Material[] | null,
    ) { }
}
