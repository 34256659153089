export enum PersistentMutations {
  SetLocale = 'setLocale',
  SetStream = 'setStream',
  SetTimezones = 'setTimezones',
  SetCurrencyCodes = 'setCurrencyCodes',
  SetMeasurements = 'setMeasurements',
  SetServices = 'setServices',
  SetDeviceBaseModels = 'setDeviceBaseModels',
  SetCurrentUser = 'setCurrentUser',
  SetMaterialGroups = 'setMaterialGroups',
  SetTechnologies = 'setTechnologies',
  SetRejectReasons = 'setRejectReasons',
  SetAddresses = 'setAddresses',
}

export enum PersistentActions {
  SetLocale = 'persistent/setLocale',
  SetStream = 'persistent/setStream',
  SetTimezones = 'persistent/setTimezones',
  SetCurrencyCodes = 'persistent/setCurrencyCodes',
  SetMeasurements = 'persistent/setMeasurements',
  SetServices = 'persistent/setServices',
  SetDeviceBaseModels = 'persistent/setDeviceBaseModels',
  SetCurrentUser = 'persistent/setCurrentUser',
  SetMaterialGroups = 'persistent/setMaterialGroups',
  SetTechnologies = 'persistent/setTechnologies',
  SetRejectReasons = 'persistent/setRejectReasons',
  SetAddresses = 'persistent/setAddresses',
}

export enum PersistentGetters {
  GetLocale = 'persistent/getLocale',
  GetStream = 'persistent/getStream',
  GetTimezones = 'persistent/getTimezones',
  GetCurrencyCodes = 'persistent/getCurrencyCodes',
  GetMeasurements = 'persistent/getMeasurements',
  GetServices = 'persistent/getServices',
  GetDeviceBaseModels = 'persistent/getDeviceBaseModels',
  IsAuthenticated = 'persistent/isAuthenticated',
  CurrentUser = 'persistent/currentUser',
  GetMaterialGroups = 'persistent/getMaterialGroups',
  GetTechnologies = 'persistent/getTechnologies',
  GetRejectReasons = 'persistent/getRejectReasons',
  GetAddresses = 'persistent/getAddresses',
}
