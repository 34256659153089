import { User } from '@/models/user';
import PersistentState from './persistentState';

const state: PersistentState = {
  locale: 'es',
  stream: '',
  timezones: [],
  currencyCodes: [],
  measurements: [],
  deviceBaseModels: [],
  services: [],
  currentUser: {} as User,
  materialGroups: [],
  technologies: [],
  rejectReasons: [],
  addresses: [],
};

export default state;
