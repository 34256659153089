
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component
export default class AddressTypeSelect extends Vue {
    @Prop({ required: true })
    public addressTypes!: number[];

    @Prop({ default: '' })
    public value!: string;

    @Prop({ default: false })
    public required!: boolean;

    @Prop({ default: false })
    public disabled!: boolean;

    // public mounted(): void {}

    public handleSelect(value: string) {
        this.$emit('input', value);
        this.$emit('change', value);
    }
}
