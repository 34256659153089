
import { Component, Vue } from 'vue-property-decorator';
import { ReportsMenu } from '@/models/menu';
import { Routes } from '@/router';

@Component
export default class Menu extends Vue {
  public powerBiReportsMenu: ReportsMenu[] = [];
  public routes = Routes;

  public get menus() {
    return this.powerBiReportsMenu.reduce((rv: any, x: ReportsMenu) => {
      (rv[x.categoryMenu.name] = rv[x.categoryMenu.name] || []).push(x);
      return rv;
    }, {});
  }

  public updateShowMenu() {
    this.$emit('menuchange');
  }
}
