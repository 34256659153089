
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import { Shipping } from '../../models/shipping';
    import { tenantsService } from '../../services/tenants.service';
    @Component
    export default class ShippingsSelect extends Vue {
        @Prop({ required: true })
        public tenantId!: number;

        @Prop({ default: '' })
        public value!: string;

        @Prop({ default: false })
        public required!: boolean;

        @Prop({ default: false })
        public disabled!: boolean;

        public shippings: Shipping[] = [];

        private loading: boolean = false;

        public mounted(): void {
            this.getItems();
        }

        @Watch('tenantId')
        public async getItems() {
            this.loading = true;
            this.shippings = await tenantsService.getShippings(this.tenantId);
            this.loading = false;
        }

        public handleSelect(value: string) {
            this.$emit('input', value);
            this.$emit('change', value);
        }

        private get hasAnyShippings(): boolean {
            return this.shippings && this.shippings.length > 0;
        }

        private get isLoading(): boolean {
            return this.loading;
        }
    }
