export enum Roles {
  SuperAdmin = 1,
  Admin = 2,
  Viewer = 3,
  Global = 4,
}

export enum UserBelongsTo {
  Subsidiary = 1,
  Tenant = 2,
  EndCustomer = 3,
}
