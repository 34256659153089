import { Store } from 'vuex';
import { RootActions } from '@/store/enums';
import RootState from '@/store/rootState';

export class SpinnerPluginService {
  constructor(private store: Store<RootState>) {}

  public showSpinner() {
    this.store.dispatch(RootActions.ShowSpinner);
  }

  public removeSpinner() {
    this.store.dispatch(RootActions.RemoveSpinner);
  }
}
