import axios from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import router, { Routes } from '@/router';

export function ResponseErrorInterceptor(appInsights: any) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      appInsights.trackException(error);

      if (error.response?.status === 401) {
        router.push({ name: Routes.Login });
      }

      if (hasErrorResponse(error) && !isPermissionError(error)) {
        const errorMessage: string = buildErrorMessage(error);

        Toast.open({
          duration: 5000,
          message: `${errorMessage}`,
          position: 'is-top',
          type: 'is-danger',
        });
      }
      return new Promise((resolve, reject) => {
        reject(error);
      });
    },
  );

  function buildErrorMessage(error: any) {
    let errorMessage: string = error.response.data.title;

    if (error.response.data.detail) {
      errorMessage = error.response.data.detail;
    }

    if (error.response.data.errors) {
      const errorProps = Object.keys(error.response.data.errors);
      errorMessage = errorProps
        .map((prop, index) => {
          return error.response.data.errors[prop];
        })
        .join('<br/>');
    }
    return errorMessage;
  }

  function isPermissionError(error: any) {
    return error.response?.status === 401 || error.response?.status === 403;
  }

  function hasErrorResponse(error: any) {
    return error.response && error.response.data && error.response.data.title;
  }
}
