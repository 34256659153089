
import { Vue, Prop, Component } from 'vue-property-decorator';
import { QuotePart as Part } from '@/models/QuotePart';
import { MaterialGroup } from '@/models/materialGroup';
import { Technology } from '@/models/technology';
import QuoteElement from './QuoteElement.vue';
import { Measurement } from '@/models/measurement';
import { PersistentGetters } from '@/store/persistent/enums';

@Component({
  components: {
    'sc-quote-element': QuoteElement,
  },
})
export default class QuotePart extends Vue {
  @Prop({ required: true })
  public part!: Part;

  @Prop({ required: false, default: false })
  public readOnly!: boolean;

  // @Prop({ required: false, default: 0 })
  // public measurementUnitOfTenant!: number;

  // @Prop({ required: true })
  // public lengthUnit!: string;

  @Prop({ required: true })
  public currencySymbol!: string;

  @Prop({ required: false, default: () => [] })
  public materialGroups!: MaterialGroup[];

  @Prop({ required: false, default: () => [] })
  public technologies!: Technology[];

  @Prop({required: false, default: false})
  public selected!: boolean;

  @Prop({required: false, default: false})
  public collapsed!: boolean;

  public items: Measurement[] = [];

  public mounted(): void {
    this.items = this.getItems();
  }

  public getItems() {
    return this.$store.getters[PersistentGetters.GetMeasurements] as Measurement[];
  }

  public get isValid() {
    return (
      this.part.materialGroupId > 0 &&
      this.part.materialId > 0 &&
      this.part.thickness > 0 &&
      this.part.units > 0 &&
      this.part.technologies.length > 0 &&
      this.part.measurementUnit > 0 &&
      !this.part.isMaterialError &&
      !this.part.isThicknessError
    );
  }

  public hasMaterials(): boolean {
    return this.part.possibleMaterials && this.part.possibleMaterials.length > 0;
  }

  public hasMaterialFormats(): boolean {
    return this.part.possibleMaterialFormats && this.part.possibleMaterialFormats.length > 0;
  }

  public chevronClicked() {
    this.$emit('chevronClicked');
  }

  public deleteClicked() {
    this.$emit('deleteClicked');
  }

  public materialGroupChanged() {
    this.$emit('materialGroupChanged');
  }

  public materialChanged() {
    this.$emit('materialChanged');
  }

  public thicknessChanged() {
    this.$emit('thicknessChanged');
  }

  public unitsChanged() {
    this.$emit('unitsChanged');
  }

  // public technologiesChanged() {
  //   this.$emit('technologiesChanged');
  // }

  public deselectTechnology(option: any) {
    if (this.part.technologies && this.part.technologies.length > 0) {
      for (let i: number = 0; i < this.part.technologies.length; i++) {
        if (this.part.technologies[i].id === option.id) {
          this.part.technologies.splice(i, 1);
          break;
        }
      }
    }
    this.$emit('technologiesChanged');
  }

  public selectTechnology(option: any) {
    this.part.technologies.push(option);
    this.$emit('technologiesChanged');
  }

  public unkwnownMeasurementChanged() {
    this.$emit('measurementUnitsChanged');
  }

  public selectedPartChanged() {
    this.$emit('selectedPartChanged');
  }

  public checkSelectedTechnologyIcon(optionId: number) {
    let selectedTech: boolean = false;

    if (this.part.technologies && this.part.technologies.length > 0) {
      for (const technology of this.part.technologies) {
        if (technology.id === optionId) {
          selectedTech = true;
          break;
        }
      }
    }

    return selectedTech;
  }

  public checkOriginalTechnologies(optionId: number) {
    let showQuestionIcon: boolean = false;

    if (optionId === 1 || optionId === 2) {
      if (this.part.originalTechnologies && this.part.originalTechnologies.length > 0) {
        for (const technology of this.part.originalTechnologies) {
          if (technology.id === optionId) {
            showQuestionIcon = true;
            break;
          }
        }
      }
    }
    return showQuestionIcon;
  }
}
