import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { MaterialFormat } from '@/models/materialFormat';

class MaterialFormatsService {
    public getById(id: number): Promise<MaterialFormat> {
        return axios.get<MaterialFormat>(`${BaseUrl}/materialformats/${id}`).then((response: any) => {
            responseHandler.handleDates(response.data);
            return response.data;
        });
    }

    public create(format: MaterialFormat) {
        return axios.post(`${BaseUrl}/materialformats/`, format).then((response: any) => {
            return response.data;
        });
    }

    public update(format: MaterialFormat) {
        return axios.put(`${BaseUrl}/materialformats/`, format).then((response: any) => {
            return response.data;
        });
    }

    public delete(id: number) {
        return axios.delete(`${BaseUrl}/materialformats/${id}`).then((response: any) => {
            return response.data;
        });
    }
}

export const materialFormatsService = new MaterialFormatsService();
