export class Markup {

    public static GetEmpty(): Markup {
        return new Markup(0, '', null, 0, null);
    }

    public static GetCopy(original: Markup): Markup {
        return new Markup(
            original.id,
            original.name,
            original.percentage,
            original.tenantId,
            original.description,
        );
    }

    constructor(
        public id: number,
        public name: string,
        public percentage: number | null,
        public tenantId: number,
        public description: string | null,
    ) {}
}
