import axios from 'axios';
import { BaseUrl } from '../models/constants';
import { responseHandler } from './response.handler';
import { MaterialGroup } from '../models/materialGroup';
import { Material } from '../models/material';

class MaterialGroupsService {

    public getById(id: number): Promise<MaterialGroup> {
        return axios.get<MaterialGroup>(`${BaseUrl}/materialgroups/${id}`).then((response: any) => {
            responseHandler.handleDates(response.data);
            return response.data;
        });
    }

    public create(materialGroup: MaterialGroup) {
        return axios.post(`${BaseUrl}/materialgroups/`, materialGroup).then((response: any) => {
            return response.data;
        });
    }

    public update(materialGroup: MaterialGroup) {
        return axios.put(`${BaseUrl}/materialgroups/`, materialGroup).then((response: any) => {
            return response.data;
        });
    }

    public delete(id: number) {
        return axios.delete(`${BaseUrl}/materialgroups/${id}`).then((response: any) => {
            return response.data;
        });
    }

    public getMaterials(id: number): Promise<Material[]> {
        return axios.get(`${BaseUrl}/materialgroups/${id}/materials`).then((response: any) => {
            return response.data;
        });
    }
}

export const materialGroupsService = new MaterialGroupsService();
