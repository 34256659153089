
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import Button from '../Button.vue';
import MaintainTenantDetails from './MaintainTenantDetails.vue';

@Component({
  components: {
    'sc-maintain-tenant': MaintainTenantDetails,
    'sc-button': Button,
  },
})
export default class CreateTenantModal extends Vue {
  public form: any = null;
  public tenant: Tenant = Tenant.GetEmpty();
  public modalVisible = false;

  @Ref('a')
  public maintainTenant!: MaintainTenantDetails;

  public handleChange(): void {
    this.tenant = Tenant.GetCopy(this.tenant);
  }

  public openModal(): void {
    this.modalVisible = true;
    this.tenant = Tenant.GetEmpty();
  }

  public closeModal() {
    this.modalVisible = false;
    this.$emit('onClose');
  }
}
