import { CurrencyCode } from '@/models/currencyCode';
import { Measurement } from '@/models/measurement';
import { TimeZone } from '@/models/timeZone';
import { MutationTree } from 'vuex';
import PersistentState from './persistentState';
import { DeviceBaseModel } from '@/models/deviceBaseModel';
import { TenantService } from '@/models/tenantService';
import { User } from '@/models/user';
import { MaterialGroup, Technology } from '@/models/QuotePart';
import { RejectReason } from '../../models/rejectReason';
import { Address } from '@/models/address';

const mutations: MutationTree<PersistentState> = {
  setLocale(state: PersistentState, locale: string) {
    state.locale = locale;
  },
  setStream(state: PersistentState, stream: string) {
    state.stream = stream;
  },
  setTimezones(state: PersistentState, timezones: TimeZone[]) {
    state.timezones = timezones;
  },
  setCurrencyCodes(state: PersistentState, currencyCodes: CurrencyCode[]) {
    state.currencyCodes = currencyCodes;
  },
  setMeasurements(state: PersistentState, measurements: Measurement[]) {
    state.measurements = measurements;
  },
  setDeviceBaseModels(state: PersistentState, deviceBaseModels: DeviceBaseModel[]) {
    state.deviceBaseModels = deviceBaseModels;
  },
  setServices(state: PersistentState, services: TenantService[]) {
    state.services = services;
  },
  setCurrentUser(state: PersistentState, value: User) {
    state.currentUser = value;
  },
  setMaterialGroups(state: PersistentState, value: MaterialGroup[]) {
    state.materialGroups = value;
  },
  setTechnologies(state: PersistentState, value: Technology[]) {
    state.technologies = value;
  },
  setRejectReasons(state: PersistentState, value: RejectReason[]) {
    state.rejectReasons = value;
  },
  setAddresses(state: PersistentState, value: Address[]) {
    state.addresses = value;
  },
};

export default mutations;
