
    import { Routes } from '@/router';
    import { Component, Vue } from 'vue-property-decorator';
    import TenantProperties from './EndCustomerProperties.vue';
    import UsersWithParent from '@/views/users/UsersWithParent.vue';
    import { EndCustomer } from '@/models/endCustomer';
    import { endCustomersService } from '@/services/endCustomers.service';
    import MaintainEndCustomer from '@/components/endCustomers/MaintainEndCustomer.vue';
    import AddressesWithParent from '@/views/addresses/AddressesWithParent.vue';

    @Component({
        components: {
            'sc-end-customer-properties': TenantProperties,
            'sc-end-customer-users': UsersWithParent,
            'sc-maintain-end-customer': MaintainEndCustomer,
            'sc-end-customer-addresses': AddressesWithParent,
        },
    })
    export default class EndCustomerDetail extends Vue {
        public id: number = 0;
        public endCustomer: EndCustomer | null = null;
        public selectedTab: number = 0;

        public async created(): Promise<void> {
            if (!this.$route.params || !this.$route.params.id) {
                this.$router.push({ name: Routes.EndCustomers });
            } else {
                this.id = +this.$route.params.id;
                await this.setById(this.id);
            }
        }

        public async setById(id: number) {
            this.$spinner.showSpinner();
            this.endCustomer = await endCustomersService.getById(id);
            this.$spinner.removeSpinner();
        }

        public get hasEndCustomer(): boolean {
            return this.endCustomer !== null;
        }

        public async update() {
            if (this.endCustomer && this.endCustomer.id) {
                await this.setById(this.endCustomer.id);
            }
        }
    }
