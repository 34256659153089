import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Markup } from '@/models/markup';

class MarkupsService {
    public get(): Promise<Markup[]> {
        return axios.get<Markup[]>(`${BaseUrl}/markups`).then((response: any) => {
            return response.data;
        });
    }

    public delete(id: number): Promise<Markup[]> {
        return axios.delete(`${BaseUrl}/markups/${id}`).then((response: any) => {
            return response.data;
        });
    }

    public update(markup: Markup) {
        return axios.put(`${BaseUrl}/markups/`, markup).then((response: any) => {
            return response.data;
        });
    }

    public create(markup: Markup) {
        return axios.post(`${BaseUrl}/markups/`, markup).then((response: any) => {
            return response.data;
        });
    }

    public getById(id: number): Promise<Markup> {
        return axios.get<Markup>(`${BaseUrl}/markups/${id}`).then((response: any) => {
            return response.data;
        });
    }

}

export const markupsService = new MarkupsService();
