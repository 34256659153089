
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import LocalSpinner from '../LocalSpinner.vue';
import QuoteElement from './QuoteElement.vue';

@Component({
  components: {
    'sc-local-spinner': LocalSpinner,
    'sc-quote-element': QuoteElement,
  },
})
export default class QuoteSummary extends Vue {
  @Prop({ required: true })
  public quoteReference!: string;

  @Prop({ required: true })
  public currencySymbol!: string;

  @Prop()
  public processingPrice!: number;

  @Prop()
  public shippingPrice!: number;

  @Prop()
  public totalPrice!: number;

  @Prop({ required: false, default: false })
  public showSpinner!: boolean;

  public get quoteReferenceFormatted() {
    return `   ${this.quoteReference}`;
  }
}
