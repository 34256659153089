import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Tenant } from '@/models/tenant';
import { responseHandler } from './response.handler';
import { User } from '@/models/user';
import { Markup } from '@/models/markup';
import { KpiGroup } from '@/models/kpiGroup';
import { Technology } from '../models/technology';
import { MaterialGroup } from '../models/materialGroup';
import { Shipping } from '../models/shipping';

class TenantsService {
  public get(): Promise<Tenant[]> {
    return axios.get<Tenant[]>(`${BaseUrl}/tenants`).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public getById(id: number): Promise<Tenant> {
    return axios.get<Tenant>(`${BaseUrl}/tenants/${id}`).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public delete(id: number): Promise<Tenant[]> {
    return axios.delete(`${BaseUrl}/tenants/${id}`).then((response: any) => {
      return response.data;
    });
  }

  public update(tenant: Tenant) {
    return axios.put(`${BaseUrl}/tenants/`, tenant).then((response: any) => {
      return response.data;
    });
  }

  public create(tenant: Tenant) {
    return axios.post(`${BaseUrl}/tenants/`, tenant).then((response: any) => {
      return response.data;
    });
  }

  public getKpiGroups(id: number): Promise<KpiGroup[]> {
    return axios.get<KpiGroup[]>(`${BaseUrl}/tenants/${id}/kpigroups`).then((response: any) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  public assignKpiGroup(id: number, kpiGroupId: number): Promise<KpiGroup[]> {
    return axios.post(`${BaseUrl}/tenants/${id}/kpigroups/${kpiGroupId}`).then((response: any) => {
      return response.data;
    });
  }

  public unAssignKpiGroup(id: number, kpiGroupId: number): Promise<KpiGroup[]> {
    return axios.delete(`${BaseUrl}/tenants/${id}/kpigroups/${kpiGroupId}`).then((response: any) => {
      return response.data;
    });
  }

  public getUsers(id: number): Promise<User[]> {
    return axios.get(`${BaseUrl}/tenants/${id}/users`).then((response: any) => {
      return response.data;
    });
  }

  public getMarkups(id: number): Promise<Markup[]> {
    return axios.get(`${BaseUrl}/tenants/${id}/markups`).then((response: any) => {
      return response.data;
    });
  }

  public getTechnologies(id: number): Promise<Technology[]> {
    return axios.get(`${BaseUrl}/tenants/${id}/technologies`).then((response: any) => {
      return response.data;
    });
  }

  public getMaterialGroups(id: number): Promise<MaterialGroup[]> {
    return axios.get(`${BaseUrl}/tenants/${id}/materialgroups`).then((response: any) => {
        return response.data;
    });
  }

  public getShippings(id: number): Promise<Shipping[]> {
    return axios.get(`${BaseUrl}/tenants/${id}/shippings`).then((response: any) => {
        return response.data;
    });
  }
}

export const tenantsService = new TenantsService();
