
import { Component, Prop, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import { Tenant } from '@/models/tenant';
import TimezonesSelect from '../inputs/TimezonesSelect.vue';
import CurrencyCodesSelect from '../inputs/CurrencyCodesSelect.vue';
import MeasurementsSelect from '../inputs/MeasurementsSelect.vue';
import SubsidiariesSelect from '../inputs/SubsidiariesSelect.vue';
import Button from '../Button.vue';
import TenantServicesSelect from '../inputs/TenantServicesSelect.vue';
import { Mode } from '@/store/enums';
import { hasPermission, hasTenantScope } from '@/utils/permissionUtils';
import { PersistentGetters } from '@/store/persistent/enums';
import { AccessLevel, UserRole } from '@/models/user';

@Component({
  components: {
    'sc-timezones-select': TimezonesSelect,
    'sc-currencycodes-select': CurrencyCodesSelect,
    'sc-measurements-select': MeasurementsSelect,
    'sc-subsidiaries-select': SubsidiariesSelect,
    'sc-tenantservices-select': TenantServicesSelect,
    'sc-button': Button,
  },
})
export default class MaintainTenantDetails extends Vue {
  @Prop({ default: false })
  public isModal!: boolean;
  @Prop({ default: Mode.View })
  public mode!: Mode;
  @Prop({ default: Tenant.GetEmpty() })
  public tenant!: Tenant;

  public editTenant: Tenant = Tenant.GetEmpty();

  public form: any = null;

  public isEditMode: boolean = this.mode === Mode.Edit;
  public isCreateMode: boolean = this.mode === Mode.Create;
  public isViewMode: boolean = this.mode === Mode.View;

  public created() {
    this.editTenant = Tenant.GetCopy(this.tenant);
  }

  public handleChange(): void {
    this.editTenant = Tenant.GetCopy(this.editTenant);
  }

  public setCreateMode() {
    this.mode = Mode.Create;
    this.editTenant = Tenant.GetEmpty();
  }
  public setEditMode(editableTenant: Tenant) {
    this.mode = Mode.Edit;
    this.editTenant = editableTenant;
  }
  public setViewMode(editableTenant: Tenant) {
    this.mode = Mode.View;
    this.editTenant = editableTenant;
  }

  public get actionTitle() {
    if (this.isCreateMode) {
      return this.$i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      if (this.isReadOnly) {
        return this.$i18n.t('action.view').toString();
      } else {
        return this.$i18n.t('action.save').toString();
      }
    } else {
      return this.$i18n.t('action.close').toString();
    }
  }
  public get isReadOnly(): boolean {
    return !(this.isSuperAdmin || this.isSubsidiaryAdmin || this.isTenantAdmin);
  }

  public get isSuperAdmin(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SuperAdmin);
  }

  public get isSubsidiaryAdmin(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.SubsidiaryAdmin);
  }

  public get isTenantAdmin(): boolean {
    return hasPermission(this.$store.getters[PersistentGetters.CurrentUser], AccessLevel.TenantAdmin);
  }

  public get isExactlyTenantAdmin(): boolean {
    const user = this.$store.getters[PersistentGetters.CurrentUser];
    return user.role === UserRole.Admin && hasTenantScope(user);
  }

  public get isSubsidiaryOrAbove(): boolean {
    return this.isSuperAdmin || this.isSubsidiaryAdmin;
  }

  public get isEnabled() {
    if (this.editTenant && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public async onSubmit() {
    this.$spinner.showSpinner();
    if (this.isCreateMode) {
      tenantsService
        .create(this.editTenant)
        .then(() => {
          this.closeModal();
        })
        .finally(() => {
            this.$spinner.removeSpinner();
            this.showSuccessMessage();
        });
    } else if (this.isEditMode) {
      tenantsService.update(this.editTenant)
        .finally(() => {
            this.$spinner.removeSpinner();
            this.$emit('updated');
            this.showSuccessMessage();
        });
    }
  }

  public closeModal() {
      this.$emit('onClose');
  }

  private showSuccessMessage() {
      const saveSuccess = this.$i18n.t('tenant.confirm.save').toString();
      this.$buefy.toast.open({ message: saveSuccess, type: 'is-success' });
  }

  private get requiredStyle() {
    if (!this.isEditMode) { return 'required'; }
    return '';
  }
}
