import { CurrencyCode } from '@/models/currencyCode';
import { Measurement } from '@/models/measurement';
import { TimeZone } from '@/models/timeZone';
import { ActionTree, ActionContext } from 'vuex';
import RootState from '../rootState';
import { PersistentMutations } from './enums';
import PersistentState from './persistentState';
import { TenantService } from '@/models/tenantService';
import { DeviceBaseModel } from '@/models/deviceBaseModel';
import { User } from '@/models/user';
import { MaterialGroup, Technology } from '@/models/QuotePart';
import { RejectReason } from '../../models/rejectReason';
import { Address } from '@/models/address';

const actions: ActionTree<PersistentState, RootState> = {
  setLocale: (store: ActionContext<PersistentState, RootState>, locale: string) => {
    store.commit(PersistentMutations.SetLocale, locale);
  },
  setStream: (store: ActionContext<PersistentState, RootState>, stream: string) => {
    store.commit(PersistentMutations.SetStream, stream);
  },
  setTimezones: (store: ActionContext<PersistentState, RootState>, timezones: TimeZone[]) => {
    store.commit(PersistentMutations.SetTimezones, timezones);
  },
  setCurrencyCodes: (store: ActionContext<PersistentState, RootState>, currencyCodes: CurrencyCode[]) => {
    store.commit(PersistentMutations.SetCurrencyCodes, currencyCodes);
  },
  setMeasurements: (store: ActionContext<PersistentState, RootState>, measurements: Measurement[]) => {
    store.commit(PersistentMutations.SetMeasurements, measurements);
  },
  setServices: (
    store: ActionContext<PersistentState, RootState>, services: TenantService[]) => {
    store.commit(PersistentMutations.SetServices, services);
  },
  setDeviceBaseModels: (store: ActionContext<PersistentState, RootState>, deviceBaseModels: DeviceBaseModel[]) => {
    store.commit(PersistentMutations.SetDeviceBaseModels, deviceBaseModels);
  },
  setCurrentUser: (store: ActionContext<PersistentState, any>, value: User) => {
    store.commit(PersistentMutations.SetCurrentUser, value);
  },
  setMaterialGroups: (store: ActionContext<PersistentState, any>, value: MaterialGroup[]) => {
    store.commit(PersistentMutations.SetMaterialGroups, value);
  },
  setTechnologies: (store: ActionContext<PersistentState, any>, value: Technology[]) => {
    store.commit(PersistentMutations.SetTechnologies, value);
  },
  setRejectReasons: (store: ActionContext<PersistentState, any>, value: RejectReason[]) => {
    store.commit(PersistentMutations.SetRejectReasons, value);
  },
  setAddresses: (store: ActionContext<PersistentState, any>, value: Address[]) => {
    store.commit(PersistentMutations.SetAddresses, value);
  },
};

export default actions;
