
import { Component, Vue, Ref, Prop } from 'vue-property-decorator';

@Component
export default class QuoteParent extends Vue {
  @Prop({ default: 'default' })
  public size!: 'default' | 'small';

  @Prop({ default: true })
  public showTitle!: boolean;

  get classSize() {
    return this.size !== 'default' ? `quotes-guidelines--${this.size}` : '';
  }
}
